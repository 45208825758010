import React from 'react';
// import {
//   Link
// } from 'react-router-dom';
import "../../styles/main.css";

// import logo from "../../documents/logo.svg";
import thought_big from "../../documents/thought_big.svg";
import linkedin_logo from "../../documents/linkedin.svg";
import github_logo from "../../documents/github.svg";
import itchio_logo from "../../documents/itchio.svg";
// import discord_logo from "../../documents/discord.svg";
import email_icon from "../../documents/email.svg";
import design_logo from "../../documents/design_logo.svg";
import dev_logo from "../../documents/dev_logo.svg";
import story_logo from "../../documents/story_logo.svg";

// import headshot from "../../documents/Shana_Hadi.jpg";
// import shana_at_work from "../../documents/shana_with_light.svg";

import Projects from '../../components/Portfolio/Projects';
// import ProjectsFeatured from '../../components/Portfolio/ProjectsFeatured';

class About extends React.Component {
  /* Scroll to top upon switching screens */
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="about-main">
        <div className="about-hello-container">
          <div className="about-hello">
            <h1 className="about-title"> Hello! I'm Shana :)</h1>
            <h3 className="about-title-blurb">
              <a href="#about-projects-container" className="about-link">Designer, Developer, Storyteller.</a>
              {/* <Link to="/projects" className="about-link">Designer, Developer, Storyteller.</Link> */}
            </h3>
            <p className="about-title-blurb-more">I am dedicated to understanding user needs, creating effective solutions, and engineering all aspects of the user experience and interface. Let's talk! </p>
            <div className="about-external-link-container">
              <a href="mailto:shana@shanahadi.com?subject=Hello!">
                <img src={email_icon} className="external-logo-img" alt="mail" />
              </a>
              <a href="https://www.linkedin.com/in/shanahadi/" target="_blank" rel="noreferrer">
                <img src={linkedin_logo} className="external-logo-img" alt="linkedin" />
              </a>
              <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer">
                <img src={github_logo} className="external-logo-img" alt="github" />
              </a>
              <a href="https://sunfirewings.itch.io/" target="_blank" rel="noreferrer">
                <img src={itchio_logo} className="external-logo-img" alt="itchio" />
              </a>
              {/* <a href="https://sunfirewings.itch.io/" target="_blank" rel="noreferrer">
                <img src={discord_logo} className="external-logo-img" alt="discord" />
              </a> */}
            </div>

            {/* <a href="https://www.linkedin.com/in/shanahadi/" target = "_blank">            
                    <img src={headshot} className="about-img" alt="shana_profile_image"/>
                  </a> */}
          </div>
          <div className="about-vector-container-small-screen">
            <div className="about-vector-container">
              {/* <img src={shana_at_work} className="about-vector" alt="shana-vector"/> */}
              {/*IN-LINE SVG FOR EFFICIENCY, but arguably I could've made a new component for this, todo refactor once vector design is stable */}
              <svg className="about-vector" width="1638" height="1425" viewBox="0 0 1638 1425" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="about-vector-light" d="M816.405 12.4762C817.561 10.4826 820.439 10.4826 821.595 12.4763L1637.38 1419.5C1638.54 1421.5 1637.1 1424 1634.78 1424H3.2151C0.903256 1424 -0.539812 1421.5 0.619779 1419.5L816.405 12.4762Z" fill="#FFEDB8" />
                <g className="about-stars-all">
                  <path className="about-star" d="M1034.73 542.898L1038.44 557.577C1038.63 558.333 1039.46 558.731 1040.16 558.41L1053.95 552.157C1054.66 551.836 1055.33 552.674 1054.86 553.294L1045.7 565.347C1045.23 565.967 1045.43 566.862 1046.12 567.217L1059.61 574.099C1060.3 574.453 1060.06 575.499 1059.29 575.517L1044.15 575.867C1043.37 575.885 1042.8 576.603 1042.95 577.367L1045.98 592.202C1046.14 592.965 1045.17 593.43 1044.67 592.833L1034.96 581.216C1034.46 580.619 1033.54 580.619 1033.04 581.216L1023.33 592.833C1022.83 593.43 1021.86 592.965 1022.02 592.202L1025.05 577.367C1025.2 576.603 1024.63 575.885 1023.85 575.867L1008.71 575.517C1007.94 575.499 1007.7 574.453 1008.39 574.099L1021.88 567.217C1022.57 566.862 1022.77 565.967 1022.3 565.347L1013.14 553.294C1012.67 552.674 1013.34 551.836 1014.05 552.157L1027.84 558.41C1028.54 558.731 1029.37 558.333 1029.56 557.577L1033.27 542.898C1033.46 542.143 1034.54 542.143 1034.73 542.898Z" fill="#FFDF80" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M756.727 443.898L759.608 455.298C759.799 456.053 760.626 456.452 761.336 456.13L772.045 451.275C772.754 450.953 773.423 451.792 772.952 452.412L765.835 461.772C765.363 462.392 765.568 463.287 766.262 463.642L776.735 468.987C777.428 469.341 777.19 470.387 776.411 470.405L764.656 470.676C763.877 470.694 763.304 471.413 763.46 472.176L765.811 483.697C765.967 484.46 765 484.925 764.501 484.328L756.959 475.307C756.459 474.709 755.541 474.709 755.041 475.307L747.499 484.328C747 484.925 746.033 484.46 746.189 483.697L748.54 472.176C748.696 471.413 748.123 470.694 747.344 470.676L735.589 470.405C734.81 470.387 734.572 469.341 735.265 468.987L745.738 463.642C746.432 463.287 746.637 462.392 746.165 461.772L739.048 452.412C738.577 451.792 739.246 450.953 739.955 451.275L750.664 456.13C751.374 456.452 752.201 456.053 752.392 455.298L755.273 443.898C755.464 443.143 756.536 443.143 756.727 443.898Z" fill="#CF0414" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M545.727 825.898L549.436 840.577C549.627 841.333 550.455 841.731 551.165 841.41L564.954 835.157C565.663 834.836 566.332 835.674 565.861 836.294L556.697 848.347C556.225 848.967 556.43 849.862 557.123 850.217L570.609 857.099C571.303 857.453 571.064 858.499 570.286 858.517L555.149 858.867C554.37 858.885 553.797 859.603 553.953 860.367L556.98 875.202C557.136 875.965 556.17 876.43 555.67 875.833L545.959 864.216C545.459 863.619 544.541 863.619 544.041 864.216L534.33 875.833C533.83 876.43 532.864 875.965 533.02 875.202L536.047 860.367C536.203 859.603 535.63 858.885 534.851 858.867L519.714 858.517C518.936 858.499 518.697 857.453 519.391 857.099L532.877 850.217C533.57 849.862 533.775 848.967 533.303 848.347L524.139 836.294C523.668 835.674 524.337 834.836 525.046 835.157L538.835 841.41C539.545 841.731 540.373 841.333 540.564 840.577L544.273 825.898C544.464 825.143 545.536 825.143 545.727 825.898Z" fill="#8B008B" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M466.727 668.898L469.608 680.298C469.799 681.053 470.626 681.452 471.336 681.13L482.045 676.275C482.754 675.953 483.423 676.792 482.952 677.412L475.835 686.772C475.363 687.392 475.568 688.287 476.262 688.642L486.735 693.987C487.428 694.341 487.19 695.387 486.411 695.405L474.656 695.676C473.877 695.694 473.304 696.413 473.46 697.176L475.811 708.697C475.967 709.46 475 709.925 474.501 709.328L466.959 700.307C466.459 699.709 465.541 699.709 465.041 700.307L457.499 709.328C457 709.925 456.033 709.46 456.189 708.697L458.54 697.176C458.696 696.413 458.123 695.694 457.344 695.676L445.589 695.405C444.81 695.387 444.572 694.341 445.265 693.987L455.738 688.642C456.432 688.287 456.637 687.392 456.165 686.772L449.048 677.412C448.577 676.792 449.246 675.953 449.955 676.275L460.664 681.13C461.374 681.452 462.201 681.053 462.392 680.298L465.273 668.898C465.464 668.143 466.536 668.143 466.727 668.898Z" fill="#F95409" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M643.727 381.898L645.779 390.019C645.97 390.774 646.798 391.173 647.507 390.851L655.136 387.392C655.845 387.071 656.514 387.909 656.042 388.529L650.973 395.197C650.501 395.817 650.706 396.712 651.4 397.067L658.86 400.874C659.554 401.228 659.315 402.274 658.536 402.292L650.163 402.485C649.384 402.503 648.811 403.222 648.967 403.985L650.641 412.192C650.797 412.955 649.831 413.42 649.331 412.823L643.959 406.397C643.459 405.799 642.541 405.799 642.041 406.397L636.669 412.823C636.169 413.42 635.203 412.955 635.359 412.192L637.033 403.985C637.189 403.222 636.616 402.503 635.837 402.485L627.464 402.292C626.685 402.274 626.446 401.228 627.14 400.874L634.6 397.067C635.294 396.712 635.499 395.817 635.027 395.197L629.958 388.529C629.486 387.909 630.155 387.071 630.864 387.392L638.493 390.851C639.202 391.173 640.03 390.774 640.221 390.019L642.273 381.898C642.464 381.143 643.536 381.143 643.727 381.898Z" fill="#FFDF80" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M611.727 515.898L616.265 533.857C616.456 534.612 617.284 535.011 617.993 534.689L634.863 527.04C635.572 526.718 636.241 527.557 635.77 528.177L624.559 542.922C624.087 543.542 624.292 544.437 624.985 544.792L641.484 553.212C642.178 553.566 641.939 554.612 641.16 554.63L622.642 555.058C621.863 555.076 621.291 555.794 621.446 556.557L625.15 574.706C625.305 575.469 624.339 575.935 623.839 575.337L611.959 561.126C611.459 560.529 610.541 560.529 610.041 561.126L598.161 575.337C597.661 575.935 596.695 575.469 596.85 574.706L600.554 556.557C600.709 555.794 600.137 555.076 599.358 555.058L580.84 554.63C580.061 554.612 579.822 553.566 580.516 553.212L597.015 544.792C597.708 544.437 597.913 543.542 597.441 542.922L586.23 528.177C585.759 527.557 586.428 526.718 587.137 527.04L604.007 534.689C604.716 535.011 605.544 534.612 605.735 533.857L610.273 515.898C610.464 515.143 611.536 515.143 611.727 515.898Z" fill="#A00047" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M110.727 1318.9L114.436 1333.58C114.627 1334.33 115.455 1334.73 116.165 1334.41L129.954 1328.16C130.663 1327.84 131.332 1328.67 130.861 1329.29L121.697 1341.35C121.225 1341.97 121.43 1342.86 122.123 1343.22L135.609 1350.1C136.303 1350.45 136.064 1351.5 135.286 1351.52L120.149 1351.87C119.37 1351.88 118.797 1352.6 118.953 1353.37L121.98 1368.2C122.136 1368.96 121.17 1369.43 120.67 1368.83L110.959 1357.22C110.459 1356.62 109.541 1356.62 109.041 1357.22L99.3299 1368.83C98.8305 1369.43 97.864 1368.96 98.0197 1368.2L101.047 1353.37C101.203 1352.6 100.63 1351.88 99.8509 1351.87L84.7143 1351.52C83.9358 1351.5 83.6971 1350.45 84.3907 1350.1L97.8765 1343.22C98.5704 1342.86 98.7748 1341.97 98.3033 1341.35L89.1393 1329.29C88.668 1328.67 89.3368 1327.84 90.0461 1328.16L103.835 1334.41C104.545 1334.73 105.373 1334.33 105.564 1333.58L109.273 1318.9C109.464 1318.14 110.536 1318.14 110.727 1318.9Z" fill="#CF0414" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M377.727 875.898L382.265 893.857C382.456 894.612 383.284 895.011 383.993 894.689L400.863 887.04C401.572 886.718 402.241 887.557 401.77 888.177L390.559 902.922C390.087 903.542 390.292 904.437 390.985 904.792L407.484 913.212C408.178 913.566 407.939 914.612 407.16 914.63L388.642 915.058C387.863 915.076 387.291 915.794 387.446 916.557L391.15 934.706C391.305 935.469 390.339 935.935 389.839 935.337L377.959 921.126C377.459 920.529 376.541 920.529 376.041 921.126L364.161 935.337C363.661 935.935 362.695 935.469 362.85 934.706L366.554 916.557C366.709 915.794 366.137 915.076 365.358 915.058L346.84 914.63C346.061 914.612 345.822 913.566 346.516 913.212L363.015 904.792C363.708 904.437 363.913 903.542 363.441 902.922L352.23 888.177C351.759 887.557 352.428 886.718 353.137 887.04L370.007 894.689C370.716 895.011 371.544 894.612 371.735 893.857L376.273 875.898C376.464 875.143 377.536 875.143 377.727 875.898Z" fill="#FFDF80" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M1094.73 846.898L1097.61 858.298C1097.8 859.053 1098.63 859.452 1099.34 859.13L1110.04 854.275C1110.75 853.953 1111.42 854.792 1110.95 855.412L1103.83 864.772C1103.36 865.392 1103.57 866.287 1104.26 866.642L1114.73 871.987C1115.43 872.341 1115.19 873.387 1114.41 873.405L1102.66 873.676C1101.88 873.694 1101.3 874.413 1101.46 875.176L1103.81 886.697C1103.97 887.46 1103 887.925 1102.5 887.328L1094.96 878.307C1094.46 877.709 1093.54 877.709 1093.04 878.307L1085.5 887.328C1085 887.925 1084.03 887.46 1084.19 886.697L1086.54 875.176C1086.7 874.413 1086.12 873.694 1085.34 873.676L1073.59 873.405C1072.81 873.387 1072.57 872.341 1073.27 871.987L1083.74 866.642C1084.43 866.287 1084.64 865.392 1084.17 864.772L1077.05 855.412C1076.58 854.792 1077.25 853.953 1077.96 854.275L1088.66 859.13C1089.37 859.452 1090.2 859.053 1090.39 858.298L1093.27 846.898C1093.46 846.143 1094.54 846.143 1094.73 846.898Z" fill="#8B008B" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M1117.73 670.898L1122.27 688.857C1122.46 689.612 1123.28 690.011 1123.99 689.689L1140.86 682.04C1141.57 681.718 1142.24 682.557 1141.77 683.177L1130.56 697.922C1130.09 698.542 1130.29 699.437 1130.99 699.792L1147.48 708.212C1148.18 708.566 1147.94 709.612 1147.16 709.63L1128.64 710.058C1127.86 710.076 1127.29 710.794 1127.45 711.557L1131.15 729.706C1131.31 730.469 1130.34 730.935 1129.84 730.337L1117.96 716.126C1117.46 715.529 1116.54 715.529 1116.04 716.126L1104.16 730.337C1103.66 730.935 1102.69 730.469 1102.85 729.706L1106.55 711.557C1106.71 710.794 1106.14 710.076 1105.36 710.058L1086.84 709.63C1086.06 709.612 1085.82 708.566 1086.52 708.212L1103.01 699.792C1103.71 699.437 1103.91 698.542 1103.44 697.922L1092.23 683.177C1091.76 682.557 1092.43 681.718 1093.14 682.04L1110.01 689.689C1110.72 690.011 1111.54 689.612 1111.73 688.857L1116.27 670.898C1116.46 670.143 1117.54 670.143 1117.73 670.898Z" fill="#CF0414" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M1259.73 906.898L1263.44 921.577C1263.63 922.333 1264.46 922.731 1265.16 922.41L1278.95 916.157C1279.66 915.836 1280.33 916.674 1279.86 917.294L1270.7 929.347C1270.23 929.967 1270.43 930.862 1271.12 931.217L1284.61 938.099C1285.3 938.453 1285.06 939.499 1284.29 939.517L1269.15 939.867C1268.37 939.885 1267.8 940.603 1267.95 941.367L1270.98 956.202C1271.14 956.965 1270.17 957.43 1269.67 956.833L1259.96 945.216C1259.46 944.619 1258.54 944.619 1258.04 945.216L1248.33 956.833C1247.83 957.43 1246.86 956.965 1247.02 956.202L1250.05 941.367C1250.2 940.603 1249.63 939.885 1248.85 939.867L1233.71 939.517C1232.94 939.499 1232.7 938.453 1233.39 938.099L1246.88 931.217C1247.57 930.862 1247.77 929.967 1247.3 929.347L1238.14 917.294C1237.67 916.674 1238.34 915.836 1239.05 916.157L1252.84 922.41C1253.54 922.731 1254.37 922.333 1254.56 921.577L1258.27 906.898C1258.46 906.143 1259.54 906.143 1259.73 906.898Z" fill="#F95409" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M1313.73 1117.9L1317.44 1132.58C1317.63 1133.33 1318.46 1133.73 1319.16 1133.41L1332.95 1127.16C1333.66 1126.84 1334.33 1127.67 1333.86 1128.29L1324.7 1140.35C1324.23 1140.97 1324.43 1141.86 1325.12 1142.22L1338.61 1149.1C1339.3 1149.45 1339.06 1150.5 1338.29 1150.52L1323.15 1150.87C1322.37 1150.88 1321.8 1151.6 1321.95 1152.37L1324.98 1167.2C1325.14 1167.96 1324.17 1168.43 1323.67 1167.83L1313.96 1156.22C1313.46 1155.62 1312.54 1155.62 1312.04 1156.22L1302.33 1167.83C1301.83 1168.43 1300.86 1167.96 1301.02 1167.2L1304.05 1152.37C1304.2 1151.6 1303.63 1150.88 1302.85 1150.87L1287.71 1150.52C1286.94 1150.5 1286.7 1149.45 1287.39 1149.1L1300.88 1142.22C1301.57 1141.86 1301.77 1140.97 1301.3 1140.35L1292.14 1128.29C1291.67 1127.67 1292.34 1126.84 1293.05 1127.16L1306.84 1133.41C1307.54 1133.73 1308.37 1133.33 1308.56 1132.58L1312.27 1117.9C1312.46 1117.14 1313.54 1117.14 1313.73 1117.9Z" fill="#A00047" stroke="black" stroke-width="0.5" />
                  <path className="about-star" d="M1480.73 1268.9L1485.27 1286.86C1485.46 1287.61 1486.28 1288.01 1486.99 1287.69L1503.86 1280.04C1504.57 1279.72 1505.24 1280.56 1504.77 1281.18L1493.56 1295.92C1493.09 1296.54 1493.29 1297.44 1493.99 1297.79L1510.48 1306.21C1511.18 1306.57 1510.94 1307.61 1510.16 1307.63L1491.64 1308.06C1490.86 1308.08 1490.29 1308.79 1490.45 1309.56L1494.15 1327.71C1494.31 1328.47 1493.34 1328.93 1492.84 1328.34L1480.96 1314.13C1480.46 1313.53 1479.54 1313.53 1479.04 1314.13L1467.16 1328.34C1466.66 1328.93 1465.69 1328.47 1465.85 1327.71L1469.55 1309.56C1469.71 1308.79 1469.14 1308.08 1468.36 1308.06L1449.84 1307.63C1449.06 1307.61 1448.82 1306.57 1449.52 1306.21L1466.01 1297.79C1466.71 1297.44 1466.91 1296.54 1466.44 1295.92L1455.23 1281.18C1454.76 1280.56 1455.43 1279.72 1456.14 1280.04L1473.01 1287.69C1473.72 1288.01 1474.54 1287.61 1474.73 1286.86L1479.27 1268.9C1479.46 1268.14 1480.54 1268.14 1480.73 1268.9Z" fill="#FFDF80" stroke="black" stroke-width="0.5" />
                </g>

                <path d="M882.752 579.069C888.757 555.595 907.27 518.089 933.286 555.855C925.443 551.297 904.355 549.559 882.752 579.069Z" fill="black" stroke="black" stroke-width="2.4" />
                <path d="M593.624 1327.69C665.181 1306.62 700.534 1301.01 725.834 1312.55C743.788 1325.53 770.883 1358.84 735.627 1388.26H549.552C539.757 1345 578.932 1332.02 593.624 1327.69Z" fill="#FFBA8B" stroke="black" stroke-width="14.4" />
                <path d="M611.799 698.598C654.412 548.869 771.925 525.514 827.792 536.262C842.992 540.156 886.854 561.222 888.774 574.662L827.792 742.565L814.809 938.95L675.535 994.641C658.224 995.618 585.788 1023.13 648.773 886.664C706.373 761.864 562.373 872.263 611.799 698.598Z" fill="black" stroke="black" stroke-width="4.8" />
                <path d="M882.844 899.824L891.87 934.408C892.184 935.61 892.652 936.766 893.263 937.848L929.454 1001.98C934.871 1011.58 927.935 1023.46 916.913 1023.46H701.253C686.995 1023.46 681.408 1004.96 693.282 997.068L769.392 946.481C772.471 944.434 774.646 941.281 775.464 937.675L783.952 900.274C785.44 893.715 791.27 889.061 797.995 889.061H868.911C875.464 889.061 881.19 893.484 882.844 899.824Z" fill="#FFBA8B" stroke="black" stroke-width="9.6" />
                <path d="M883.973 910.84C868.361 936.232 780.49 1001 769.874 951.775C780.685 956.22 783.811 993.381 883.973 910.84Z" fill="#FFDF80" />
                <path d="M891.123 941.013C872.911 977.239 796.274 1041.72 711.644 985.471C665.31 1054.44 625.714 1179.79 761.782 1153.85C897.849 1127.91 904.704 1001.15 891.123 941.013Z" fill="#8B008B" stroke="black" stroke-width="4.8" />
                <path d="M621.437 1025.86C650.237 997.063 699.199 975.465 768.799 951.465C742.399 994.665 712.399 1064.66 741.199 1083.86C783.599 1066.26 883.999 1059.47 864.799 1025.86C844.91 991.054 890.248 953.063 897.448 941.863C942.248 965.063 1031.34 1008.34 1055.85 1047.46C1078.4 1083.46 1052.95 1347.46 996.799 1388.26H741.448C760.648 1371.46 687.163 1378.66 723.173 1313.86C709.726 1313.31 667.181 1313.06 646.373 1316.26C622.373 1316.26 611.837 1208.26 681.448 1109.86C623.837 1172.26 622.401 1316.26 536.001 1311.46H458.237C431.357 1315.3 472.799 1264.26 490.399 1239.46C519.999 1197.86 601.392 1045.91 621.437 1025.86Z" fill="#F95409" />
                <path d="M741.199 1083.86C712.399 1064.66 742.399 994.665 768.799 951.465C699.199 975.465 650.237 997.063 621.437 1025.86C601.392 1045.91 519.999 1197.86 490.399 1239.46C472.799 1264.26 431.357 1315.3 458.237 1311.46H536.001C622.401 1316.26 623.837 1172.26 681.448 1109.86C611.837 1208.26 622.373 1316.26 646.373 1316.26C667.181 1313.06 709.726 1313.31 723.173 1313.86M741.199 1083.86C777.199 1107.86 811.999 1139.81 754.401 1172.26C734.4 1183.53 704.959 1215.63 747.199 1253.87M741.199 1083.86C783.599 1066.26 883.999 1059.47 864.799 1025.86C844.91 991.054 890.248 953.063 897.448 941.863C942.248 965.063 1031.34 1008.34 1055.85 1047.46C1078.4 1083.46 1052.95 1347.46 996.799 1388.26H741.448C760.648 1371.46 687.163 1378.66 723.173 1313.86M723.173 1313.86C800.773 1317.06 937.279 1300.9 919.999 1239.46C902.719 1178.02 944.648 1125.86 959.848 1109.86" stroke="black" stroke-width="7.2" />
                <path d="M775.999 1210.67C775.999 1217.29 770.627 1222.67 763.999 1222.67C757.372 1222.67 751.999 1217.29 751.999 1210.67C751.999 1204.04 757.372 1198.67 763.999 1198.67C770.627 1198.67 775.999 1204.04 775.999 1210.67Z" fill="#FFDF80" stroke="black" stroke-width="4.8" />
                <path d="M814.374 1167.46C814.374 1174.09 809.002 1179.46 802.374 1179.46C795.747 1179.46 790.374 1174.09 790.374 1167.46C790.374 1160.83 795.747 1155.46 802.374 1155.46C809.002 1155.46 814.374 1160.83 814.374 1167.46Z" fill="#FFDF80" stroke="black" stroke-width="4.8" />
                <path d="M823.973 1105.06C823.973 1111.69 818.601 1117.06 811.973 1117.06C805.346 1117.06 799.973 1111.69 799.973 1105.06C799.973 1098.43 805.346 1093.06 811.973 1093.06C818.601 1093.06 823.973 1098.43 823.973 1105.06Z" fill="#FFDF80" stroke="black" stroke-width="4.8" />
                <path d="M792.799 709.681C807.999 697.681 843.199 679.921 862.399 704.881" stroke="black" stroke-width="4.8" />
                <path d="M733.328 910.924C776.72 940.713 868.343 887.697 899.501 864.45C901.717 862.797 903.305 860.546 904.182 857.924C927.481 788.232 954.872 657.841 865.468 603.399C775.494 548.61 713.65 611.024 693.092 650.447C692.562 651.462 692.194 652.49 691.941 653.607C674.759 729.492 668.599 866.486 733.328 910.924Z" fill="#FFBA8B" stroke="black" stroke-width="4.8" />
                <path d="M763.591 773.865C758.224 788.049 740 803.344 740 809.325C740 816.416 745.368 819.568 747.972 821.144C753.179 821.932 766.034 823.508 768.8 816.416" stroke="black" stroke-width="4.8" />
                <ellipse cx="818.838" cy="736.279" rx="26.4" ry="13.625" transform="rotate(-12.8294 818.838 736.279)" fill="white" />
                <path d="M790.037 745.845C796.653 729.994 816.841 704.445 848.04 733.245C851.513 736.147 862.44 740.445 864.84 728.445" stroke="black" stroke-width="9.6" />
                <path d="M788.4 740.66C795.137 746.969 813.769 756.164 836 744.05" stroke="black" stroke-width="4.8" />
                <ellipse cx="815.598" cy="743.865" rx="8.4" ry="8.4" fill="black" />
                <ellipse cx="22.8041" cy="13.625" rx="22.8041" ry="13.625" transform="matrix(-0.995832 -0.0912107 -0.0912106 0.995832 737.098 730.209)" fill="white" />
                <path d="M738.584 749.327C733.247 733.59 717.543 714.444 694.053 737.833C691.093 740.781 680.644 745.011 678.797 733.06" stroke="black" stroke-width="9.6" />
                <ellipse cx="714.799" cy="748.666" rx="8.4" ry="8.4" fill="black" />
                <path d="M696.8 749.866C703.287 756.431 712.515 759.105 735.199 747.861" stroke="black" stroke-width="4.8" />
                <path d="M737.322 714.481C727.955 707.386 702.112 694.345 683.404 712.706" stroke="black" stroke-width="4.8" />
                <path d="M788.399 713.46C799.995 700.027 834.696 677.832 865.199 710.661" stroke="black" stroke-width="4.8" />
                <path d="M802.362 850.666C795.071 885.508 762.868 886.578 747.678 882.757C740.387 879.09 739.577 864.419 740.083 857.543L802.362 850.666Z" fill="#242424" />
                <path d="M759.162 874.666C753.233 871.093 744.65 874.559 742.362 877.066L744.947 882.684C752.249 884.752 772.41 884.311 785.485 876.025C780.762 869.866 764.683 872.812 759.162 874.666Z" fill="#CF0414" />
                <rect x="738.284" y="856.882" width="59.4701" height="11.1173" transform="rotate(-3.00667 738.284 856.882)" fill="#FFFEFE" />
                <path d="M799.961 853.066C791.536 886.59 762.313 885.542 744.761 881.866L739.98 869.574L735.198 857.283L799.961 853.066Z" stroke="black" stroke-width="4.8" />
                <path d="M883.044 927.555C834.794 891.057 854.403 864.079 876.799 819.466C886.399 801.066 904.639 757.066 900.799 728.266C895.999 692.266 847.291 699.905 845.245 616.886C842.368 609.623 817.105 595.905 879.2 569.865C929.317 553.865 1006.4 593.863 996.8 680.265C1015.06 721.182 1064 764.265 1020.37 843.642C966.959 940.836 1089.26 950.932 1090.4 1037.87C1090.85 1072.58 1056.16 1102.71 1042.4 1052.27C1021.64 1083.51 1018.4 1107.47 1044.63 1130.37C1016.85 1161.72 965.44 1090.46 973.999 1037.28C950.117 1093.49 921.995 1080.78 893.639 1083.45C945.207 1000.4 910.206 953.055 883.044 927.555Z" fill="black" />
                <path d="M845.245 616.886C842.368 609.623 817.105 595.905 879.2 569.865C929.317 553.865 1006.4 593.863 996.8 680.265C1015.06 721.182 1064 764.265 1020.37 843.642C966.959 940.836 1089.26 950.932 1090.4 1037.87C1090.85 1072.58 1056.16 1102.71 1042.4 1052.27C1021.64 1083.51 1018.4 1107.47 1044.63 1130.37C1012.74 1166.35 949.747 1067.19 981.979 1015.3C956.512 1096.58 925.221 1080.48 893.639 1083.45C945.207 1000.4 910.206 953.055 883.044 927.555C834.794 891.057 854.403 864.079 876.799 819.466C886.399 801.066 904.639 757.066 900.799 728.266C895.999 692.266 847.291 699.905 845.245 616.886Z" stroke="black" stroke-width="4.8" />
                <path d="M729.414 594.385C779.257 526.916 857.106 597.28 872.082 625.509C848.525 612.355 814.36 613.209 790.908 637.095C752.615 676.096 677.171 678.515 626.673 666.847C707.318 631.51 734.596 607.198 729.414 594.385Z" fill="black" />
                <path d="M663.197 1397.66V1402.46C663.197 1410.42 669.644 1416.86 677.597 1416.86H989.597C997.55 1416.86 1004 1410.42 1004 1402.46V1397.66C1004 1389.71 997.55 1383.26 989.597 1383.26H677.597C669.644 1383.26 663.197 1389.71 663.197 1397.66Z" fill="#ECECEC" stroke="black" stroke-width="14.4" />
                <path d="M218.408 1379.25V1407.26C218.408 1412.56 222.707 1416.86 228.008 1416.86H653.274C658.576 1416.86 662.874 1412.56 662.874 1407.26V1379.25C662.874 1373.95 658.576 1369.65 653.274 1369.65H228.008C222.707 1369.65 218.408 1373.95 218.408 1379.25Z" fill="#A3A3A3" stroke="black" stroke-width="14.4" />
                <path d="M663.798 1340.53L568.858 1001.99C563.629 983.348 546.629 970.46 527.263 970.46H148.424C119.981 970.46 99.2997 997.472 106.72 1024.93L198.208 1363.47C203.3 1382.31 220.394 1395.4 239.912 1395.4H622.203C650.816 1395.4 671.524 1368.08 663.798 1340.53Z" fill="#ECECEC" stroke="black" stroke-width="14.4" />
                <rect x="689.481" y="1397.55" width="16.096" height="4.29231" stroke="black" stroke-width="4.29231" />
                <rect x="718.026" y="1397.55" width="16.096" height="4.29231" stroke="black" stroke-width="4.29231" />
                <path d="M340.33 1115.4C343.986 1123.37 344.093 1131.27 337.476 1140.47C327.15 1135.06 311.984 1141.24 305.42 1140.47C319.971 1152.86 303.07 1159.66 295.971 1160.06C295.971 1174.46 307.971 1175.26 312.771 1174.46C327.891 1171.72 338.096 1180.18 340.15 1183.92C341.665 1156.59 369.093 1157.69 375.788 1163.84C380.465 1155.21 390.288 1153.59 401.6 1154.29C408.096 1149.63 404.859 1145.91 403.599 1140.51C403.091 1138.33 402.475 1128.54 406.54 1123.76C397.405 1125.21 376.557 1122.47 373.601 1134.16C367.571 1119.24 354.824 1117.21 340.33 1115.4Z" fill="#A00047" />
                <path d="M341.19 1257.22C346.004 1254.84 353.236 1242.97 344.652 1232.81C341.74 1234.06 326.629 1233.75 325.4 1242.55C306.527 1228.93 316.493 1212.94 320.35 1206.32C296.01 1219.39 290.314 1200.31 288.771 1186.46C293.054 1194.46 302.192 1190.13 305.42 1187.93C317.344 1179.8 337.774 1185.29 341.085 1197.46C343.971 1208.06 356.5 1223.61 372.133 1219.32C367.758 1226.72 373.708 1238.81 377.572 1242.55C378.277 1256.84 356.967 1259.59 341.19 1257.22Z" fill="#A00047" />
                <path d="M412.211 1248.79C407.858 1236.96 413.178 1234.34 407.618 1221.1C422.933 1222.2 422.003 1215.3 435.843 1218.95C428.19 1202.54 428.787 1190.07 418.647 1187.93C425.398 1177.9 430.694 1174.92 422.617 1144.77C421.325 1149.67 415.589 1162.42 405.205 1163.44C390.277 1162.58 384.337 1165.68 383.233 1167.33C394.386 1175.7 402.906 1202.69 380.175 1217.53C376.198 1236.4 393.317 1249.3 412.211 1248.79Z" fill="#A00047" />
                <path d="M340.33 1115.4C343.986 1123.37 344.093 1131.27 337.476 1140.47C327.15 1135.06 311.984 1141.24 305.42 1140.47C319.971 1152.86 303.07 1159.66 295.971 1160.06C295.971 1174.46 307.971 1175.26 312.771 1174.46C327.891 1171.72 338.096 1180.18 340.15 1183.92C341.665 1156.59 369.093 1157.69 375.788 1163.84C380.465 1155.21 390.288 1153.59 401.6 1154.29C408.096 1149.63 404.859 1145.91 403.599 1140.51C403.091 1138.33 402.475 1128.54 406.54 1123.76C397.405 1125.21 376.557 1122.47 373.601 1134.16C367.571 1119.24 354.824 1117.21 340.33 1115.4Z" stroke="black" stroke-width="1.44" />
                <path d="M341.19 1257.22C346.004 1254.84 353.236 1242.97 344.652 1232.81C341.74 1234.06 326.629 1233.75 325.4 1242.55C306.527 1228.93 316.493 1212.94 320.35 1206.32C296.01 1219.39 290.314 1200.31 288.771 1186.46C293.054 1194.46 302.192 1190.13 305.42 1187.93C317.344 1179.8 337.774 1185.29 341.085 1197.46C343.971 1208.06 356.5 1223.61 372.133 1219.32C367.758 1226.72 373.708 1238.81 377.572 1242.55C378.277 1256.84 356.967 1259.59 341.19 1257.22Z" stroke="black" stroke-width="1.44" />
                <path d="M412.211 1248.79C407.858 1236.96 413.178 1234.34 407.618 1221.1C422.933 1222.2 422.003 1215.3 435.843 1218.95C428.19 1202.54 428.787 1190.07 418.647 1187.93C425.398 1177.9 430.694 1174.92 422.617 1144.77C421.325 1149.67 415.589 1162.42 405.205 1163.44C390.277 1162.58 384.337 1165.68 383.233 1167.33C394.386 1175.7 402.906 1202.69 380.175 1217.53C376.198 1236.4 393.317 1249.3 412.211 1248.79Z" stroke="black" stroke-width="1.44" />
                <path d="M383.335 1184.05C387.063 1195.08 382.445 1206.54 373.119 1209.69C363.793 1212.84 353.174 1206.53 349.445 1195.5C345.717 1184.47 350.336 1173.02 359.661 1169.86C368.987 1166.71 379.607 1173.02 383.335 1184.05Z" fill="#8B008B" stroke="black" stroke-width="0.48" />
                <ellipse cx="365.7" cy="1189.44" rx="10.4211" ry="14.7077" transform="rotate(-18.6717 365.7 1189.44)" fill="black" />
                <rect x="-2.4" y="2.4" width="331.2" height="36.3429" transform="matrix(-1 0 0 1 1397.58 1372.49)" fill="white" stroke="black" stroke-width="4.8" />
                <path d="M1397.57 1364.26H1054.03C1050.24 1364.26 1047.17 1367.33 1047.17 1371.12C1047.17 1374.9 1050.24 1377.98 1054.03 1377.98H1385.57C1393.53 1377.98 1399.97 1384.42 1399.97 1392.38C1399.97 1400.33 1393.53 1406.78 1385.57 1406.78H1054.72C1050.55 1406.78 1047.17 1410.15 1047.17 1414.32C1047.17 1418.48 1050.55 1421.86 1054.72 1421.86H1397.57C1410.83 1421.86 1421.57 1411.12 1421.57 1397.86V1388.26C1421.57 1375.01 1410.83 1364.26 1397.57 1364.26Z" fill="#A00047" stroke="black" stroke-width="4.8" />
                <rect x="-2.4" y="2.4" width="249.354" height="36.3429" transform="matrix(-1 0 0 1 1363.85 1314.89)" fill="white" stroke="black" stroke-width="4.8" />
                <path d="M1359.17 1306.66H1106.83C1103.04 1306.66 1099.97 1309.73 1099.97 1313.52C1099.97 1317.31 1103.04 1320.38 1106.83 1320.38H1352.44C1360.39 1320.38 1366.84 1326.82 1366.84 1334.78C1366.84 1342.73 1360.39 1349.18 1352.44 1349.18H1107.52C1103.35 1349.18 1099.97 1352.55 1099.97 1356.72C1099.97 1360.88 1103.35 1364.26 1107.52 1364.26H1359.17C1372.43 1364.26 1383.17 1353.52 1383.17 1340.26V1330.66C1383.17 1317.41 1372.43 1306.66 1359.17 1306.66Z" fill="#FFDF80" stroke="black" stroke-width="4.8" />
                <path d="M1168.63 1304.26L1156.93 1121.86H1250.22L1238.52 1304.26H1168.63Z" fill="#889927" stroke="black" stroke-width="4.8" />
                <path d="M1219.18 1121.86L1223.98 1160.26M1207.18 1121.86L1202.38 1177.06" stroke="black" stroke-width="4.8" />
                <rect x="1213.29" y="1156.52" width="21.6" height="21.6" transform="rotate(-5.6958 1213.29 1156.52)" fill="#FFDF80" stroke="black" stroke-width="2.4" />
                <rect x="1191.42" y="1173.57" width="21.6" height="21.6" transform="rotate(5.65894 1191.42 1173.57)" fill="#F95409" stroke="black" stroke-width="2.4" />
                <rect x="1160.37" y="1101.46" width="88.8" height="12" rx="3.6" fill="#686868" stroke="black" stroke-width="2.4" />
                <rect x="1150.77" y="1111.06" width="108" height="12" rx="6" fill="#686868" stroke="black" stroke-width="2.4" />
                <path d="M1244.63 1304.95C1236.38 1309.62 1225.91 1306.72 1221.24 1298.47C1216.57 1290.22 1219.48 1279.75 1227.72 1275.09C1235.97 1270.42 1246.44 1273.32 1251.11 1281.57C1255.78 1289.82 1252.88 1300.29 1244.63 1304.95Z" fill="#8B008B" stroke="black" stroke-width="3.11998" />
                <path d="M1276.36 1296.34L1254.12 1297.48C1251.53 1297.61 1250.23 1300.67 1251.92 1302.64L1265.85 1318.76C1267.88 1321.11 1271.72 1319.38 1271.3 1316.3L1270.29 1308.82C1270.12 1307.55 1270.74 1306.31 1271.85 1305.68L1278.06 1302.17C1280.92 1300.55 1279.65 1296.17 1276.36 1296.34Z" fill="#8B008B" stroke="black" stroke-width="3.11998" />
                <path d="M1195.99 1283.7L1218.23 1282.56C1220.82 1282.43 1222.13 1279.37 1220.43 1277.4L1206.5 1261.28C1204.47 1258.93 1200.63 1260.66 1201.05 1263.74L1202.06 1271.22C1202.23 1272.49 1201.62 1273.73 1200.51 1274.36L1194.3 1277.87C1191.43 1279.49 1192.71 1283.87 1195.99 1283.7Z" fill="#8B008B" stroke="black" stroke-width="3.11998" />
                <path d="M945.937 412.577L950.48 417.9C952.366 420.109 955.423 420.917 958.154 419.928L964.912 417.479C965.932 417.11 967.038 417.753 967.221 418.823L968.322 425.27C968.826 428.218 971.154 430.516 974.108 430.98L980.942 432.054C982.042 432.227 982.699 433.375 982.291 434.412L979.995 440.243C978.867 443.108 979.753 446.374 982.175 448.276L987.251 452.263C988.139 452.96 988.139 454.304 987.251 455.001L982.175 458.988C979.753 460.89 978.867 464.155 979.995 467.021L982.291 472.852C982.699 473.889 982.042 475.036 980.942 475.209L974.108 476.284C971.154 476.748 968.826 479.046 968.322 481.994L967.221 488.441C967.038 489.51 965.932 490.154 964.912 489.784L958.154 487.336C955.423 486.347 952.366 487.155 950.48 489.364L945.937 494.687C945.242 495.501 943.984 495.501 943.289 494.687L938.746 489.364C936.86 487.155 933.803 486.347 931.072 487.336L924.314 489.784C923.294 490.154 922.188 489.51 922.005 488.441L920.904 481.994C920.4 479.046 918.072 476.748 915.117 476.284L908.284 475.209C907.183 475.036 906.526 473.889 906.935 472.852L909.231 467.021C910.359 464.155 909.473 460.89 907.051 458.988L901.975 455.001C901.087 454.304 901.087 452.96 901.975 452.263L907.051 448.276C909.473 446.374 910.359 443.108 909.231 440.243L906.935 434.412C906.526 433.375 907.183 432.227 908.284 432.054L915.117 430.98C918.072 430.516 920.4 428.218 920.904 425.27L922.005 418.823C922.188 417.753 923.294 417.11 924.314 417.479L931.072 419.928C933.803 420.917 936.86 420.109 938.746 417.9L943.289 412.577C943.984 411.763 945.242 411.763 945.937 412.577Z" fill="#FFFDFD" stroke="#8B8383" stroke-width="5.2272" />
                <path d="M961.34 456.66C971.794 456.66 967.438 446.205 963.953 440.978C958.726 433.137 946.529 447.077 943.044 454.046C944.787 454.917 950.885 456.66 961.34 456.66Z" fill="#889927" stroke="black" stroke-width="2.6136" />
                <path d="M941.162 474.696C944.323 471.128 949.124 462.003 943.044 454.045" stroke="black" stroke-width="2.6136" />
                <path d="M943.045 454.045C940.119 444.788 931.563 429.916 920.743 444.482C907.217 462.689 939.937 450.312 943.045 454.045Z" fill="#889927" stroke="black" stroke-width="2.6136" />
                <path d="M878.084 502.934L880.378 505.622C881.379 506.795 883.003 507.224 884.452 506.698L887.865 505.462C888.275 505.313 888.719 505.572 888.793 506.002L889.349 509.258C889.616 510.823 890.853 512.042 892.421 512.289L895.872 512.832C896.314 512.901 896.578 513.362 896.414 513.778L895.254 516.724C894.656 518.245 895.126 519.978 896.412 520.988L898.975 523.002C899.332 523.281 899.332 523.821 898.975 524.101L896.412 526.115C895.126 527.124 894.656 528.858 895.254 530.379L896.414 533.324C896.578 533.741 896.314 534.202 895.872 534.271L892.421 534.814C890.853 535.061 889.616 536.28 889.349 537.845L888.793 541.101C888.719 541.531 888.275 541.789 887.865 541.641L884.452 540.404C883.003 539.879 881.379 540.308 880.378 541.481L878.084 544.169C877.805 544.496 877.299 544.496 877.02 544.169L874.726 541.481C873.725 540.308 872.101 539.879 870.652 540.404L867.239 541.641C866.829 541.789 866.385 541.531 866.311 541.101L865.755 537.845C865.488 536.28 864.252 535.061 862.683 534.814L859.232 534.271C858.79 534.202 858.526 533.741 858.69 533.324L859.85 530.379C860.449 528.858 859.978 527.124 858.693 526.115L856.129 524.101C855.772 523.821 855.772 523.281 856.129 523.002L858.693 520.988C859.978 519.978 860.449 518.245 859.85 516.724L858.69 513.778C858.526 513.362 858.79 512.901 859.232 512.832L862.683 512.289C864.252 512.042 865.488 510.823 865.755 509.258L866.311 506.002C866.385 505.572 866.829 505.313 867.239 505.462L870.652 506.698C872.101 507.224 873.725 506.795 874.726 505.622L877.02 502.934C877.299 502.607 877.805 502.607 878.084 502.934Z" fill="white" stroke="#8B8383" stroke-width="3" />
                <path d="M871.215 525.08C871.215 527.121 869.561 528.776 867.519 528.776C865.478 528.776 863.823 527.121 863.823 525.08C863.823 523.039 865.478 521.384 867.519 521.384C869.561 521.384 871.215 523.039 871.215 525.08Z" fill="#889927" stroke="black" stroke-width="0.527999" />
                <path d="M881.776 525.08C881.776 527.121 880.121 528.776 878.08 528.776C876.039 528.776 874.384 527.121 874.384 525.08C874.384 523.039 876.039 521.384 878.08 521.384C880.121 521.384 881.776 523.039 881.776 525.08Z" fill="#889927" stroke="black" stroke-width="0.527999" />
                <path d="M892.338 525.081C892.338 527.122 890.683 528.777 888.642 528.777C886.6 528.777 884.946 527.122 884.946 525.081C884.946 523.039 886.6 521.385 888.642 521.385C890.683 521.385 892.338 523.039 892.338 525.081Z" fill="#889927" stroke="black" stroke-width="0.527999" />
                <path d="M833.875 18.2713L854.734 42.7108C860.333 49.2708 869.413 51.6702 877.522 48.7323L908.549 37.4906C919.855 33.3941 932.114 40.5267 934.139 52.3804L939.197 81.9813C940.692 90.7351 947.607 97.5584 956.38 98.9376L987.755 103.87C999.952 105.788 1007.23 118.51 1002.71 129.998L992.167 156.772C988.817 165.28 991.449 174.979 998.64 180.627L1021.95 198.931C1031.78 206.656 1031.78 221.553 1021.95 229.277L998.64 247.581C991.449 253.229 988.817 262.928 992.167 271.436L1002.71 298.21C1007.23 309.699 999.952 322.42 987.755 324.338L956.38 329.271C947.607 330.65 940.692 337.473 939.197 346.227L934.139 375.828C932.114 387.682 919.855 394.814 908.549 390.718L877.522 379.476C869.413 376.538 860.333 378.938 854.734 385.498L833.875 409.937C826.173 418.961 812.227 418.961 804.525 409.937L783.666 385.498C778.067 378.938 768.987 376.538 760.878 379.476L729.851 390.718C718.545 394.814 706.286 387.682 704.261 375.828L699.203 346.227C697.708 337.473 690.793 330.65 682.02 329.271L650.645 324.338C638.448 322.42 631.167 309.699 635.69 298.21L646.233 271.436C649.583 262.928 646.951 253.229 639.76 247.581L616.453 229.277C606.617 221.553 606.617 206.656 616.453 198.931L639.76 180.627C646.951 174.979 649.583 165.28 646.233 156.772L635.69 129.998C631.167 118.51 638.448 105.788 650.645 103.87L682.02 98.9376C690.793 97.5584 697.708 90.7351 699.203 81.9813L704.261 52.3804C706.286 40.5267 718.545 33.3941 729.851 37.4906L760.878 48.7323C768.987 51.6701 778.067 49.2707 783.666 42.7108L804.525 18.2713C812.227 9.24716 826.173 9.24716 833.875 18.2713Z" fill="#A09494" stroke="black" stroke-width="1.39937" />
                <path d="M833.123 26.4602L852.504 48.9794C858.096 55.4775 867.116 57.8539 875.185 54.9549L904.069 44.5761C915.357 40.5204 927.57 47.6373 929.606 59.457L934.232 86.3047C935.74 95.0555 942.662 101.869 951.436 103.237L980.281 107.734C992.512 109.641 999.808 122.411 995.24 133.917L985.748 157.823C982.354 166.371 985.009 176.133 992.265 181.784L1013.32 198.184C1023.24 205.908 1023.24 220.902 1013.32 228.626L992.265 245.025C985.009 250.677 982.354 260.439 985.748 268.987L995.24 292.893C999.808 304.398 992.512 317.168 980.281 319.075L951.436 323.573C942.662 324.941 935.74 331.754 934.232 340.505L929.606 367.352C927.57 379.172 915.357 386.289 904.069 382.233L875.185 371.855C867.116 368.956 858.096 371.332 852.504 377.83L833.123 400.349C825.425 409.293 811.574 409.293 803.876 400.349L784.496 377.83C778.903 371.332 769.883 368.956 761.815 371.855L732.93 382.233C721.642 386.289 709.429 379.172 707.393 367.352L702.767 340.505C701.259 331.754 694.337 324.941 685.563 323.573L656.719 319.075C644.487 317.168 637.191 304.398 641.759 292.893L651.251 268.987C654.645 260.439 651.99 250.677 644.734 245.025L623.678 228.626C613.76 220.902 613.76 205.908 623.678 198.184L644.734 181.784C651.99 176.133 654.645 166.371 651.251 157.823L641.759 133.917C637.191 122.411 644.487 109.641 656.719 107.734L685.563 103.237C694.337 101.869 701.259 95.0555 702.767 86.3047L707.393 59.457C709.429 47.6373 721.642 40.5204 732.93 44.5761L761.815 54.9549C769.883 57.8539 778.903 55.4775 784.496 48.9794L803.876 26.4602C811.574 17.5163 825.425 17.5163 833.123 26.4602Z" fill="#FFFDFD" stroke="black" stroke-width="1.39937" />
                <rect x="799.5" y="318.885" width="39.3741" height="22.148" fill="#CF0414" />
                <ellipse cx="819.186" cy="208.145" rx="83.67" ry="92.2834" fill="#FFDF80" stroke="black" stroke-width="3.69133" />
                <rect x="816.726" y="224.14" width="4.92177" height="61.5223" fill="black" />
                <path d="M773.037 297.502L771.948 298.009L772.223 299.179L776.068 315.531L776.336 316.67H777.506H809.31H829.679H861.329H862.523L862.773 315.503L866.157 299.721L866.407 298.553L865.318 298.064C859.576 295.486 850.609 291.721 841.827 288.752C837.437 287.267 833.068 285.973 829.158 285.127C825.374 284.308 821.89 283.879 819.199 284.175C816.278 283.802 812.625 284.165 808.712 284.936C804.685 285.73 800.265 286.98 795.881 288.425C787.111 291.314 778.382 295.012 773.037 297.502Z" fill="#A00047" stroke="black" stroke-width="2.95307" />
                <path d="M794.112 339.556L787.195 312.486H851.178L844.137 339.556H822.275C826.886 336.693 834.626 329.353 828.698 322.898C826.268 321.032 821.089 318.889 819.31 325.761C817.531 318.889 812.276 321.001 809.847 322.866C803.918 329.321 811.858 336.693 816.469 339.556H808.193H794.112Z" fill="#8B008B" stroke="black" stroke-width="2.95307" />
                <path d="M783.354 293.792C755.374 264.442 711.914 257.945 693.682 258.365C693.682 274.301 705.997 290.178 712.878 297.07C711.563 286.227 717.129 278.369 721.688 276.268C745.486 265.299 760.344 285.471 761.396 295.052C763.499 304.836 756.312 309.299 752.455 310.307C740.989 313.535 737.948 305.517 737.86 301.104C743.014 304.331 745.968 302.449 746.801 301.104C750.877 297.448 747.02 291.084 743.251 288.856C728.393 280.077 721.03 298.961 723.397 304.13C729.708 326.218 773.448 336.951 794.53 339.556C795.266 336.127 787.386 307.618 783.354 293.792Z" fill="#889927" />
                <path d="M855.02 293.792C883 264.442 926.459 257.945 944.692 258.365C944.692 274.301 932.376 290.178 925.495 297.07C926.81 286.227 921.244 278.369 916.686 276.268C892.887 265.299 878.029 285.471 876.978 295.052C874.874 304.836 882.062 309.299 885.918 310.307C897.384 313.535 900.426 305.517 900.513 301.104C895.359 304.331 892.405 302.449 891.572 301.104C887.496 297.448 889.118 291.733 892.887 289.505C912.662 279.722 917.343 298.961 914.976 304.13C908.665 326.218 864.925 336.951 843.844 339.556C843.107 336.127 850.988 307.618 855.02 293.792Z" fill="#889927" />
                <path d="M783.354 293.792C755.374 264.442 711.914 257.945 693.682 258.365C693.682 274.301 705.997 290.178 712.878 297.07C711.563 286.227 717.129 278.369 721.688 276.268C745.486 265.299 760.344 285.471 761.396 295.052C763.499 304.836 756.312 309.299 752.455 310.307C740.989 313.535 737.948 305.517 737.86 301.104C743.014 304.331 745.968 302.449 746.801 301.104C750.877 297.448 747.02 291.084 743.251 288.856C728.393 280.077 721.03 298.961 723.397 304.13C729.708 326.218 773.448 336.951 794.53 339.556C795.266 336.127 787.386 307.618 783.354 293.792Z" stroke="black" stroke-width="2.95307" />
                <path d="M855.02 293.792C883 264.442 926.459 257.945 944.692 258.365C944.692 274.301 932.376 290.178 925.495 297.07C926.81 286.227 921.244 278.369 916.686 276.268C892.887 265.299 878.029 285.471 876.978 295.052C874.874 304.836 882.062 309.299 885.918 310.307C897.384 313.535 900.426 305.517 900.513 301.104C895.359 304.331 892.405 302.449 891.572 301.104C887.496 297.448 889.118 291.733 892.887 289.505C912.662 279.722 917.343 298.961 914.976 304.13C908.665 326.218 864.925 336.951 843.844 339.556C843.107 336.127 850.988 307.618 855.02 293.792Z" stroke="black" stroke-width="2.95307" />
                <path d="M777.849 234.363C779.736 214.43 801.536 214.235 809.655 216.636L818.612 218.099C818.612 229.939 814.682 237.657 811.651 242.532C803.117 254.058 785.703 256.485 774.028 255.082C777.297 252.059 777.409 239.02 777.849 234.363Z" fill="#F95409" stroke="black" stroke-width="3.19915" />
                <path d="M861.083 234.339C859.106 214.389 836.966 214.127 828.728 216.505L819.636 217.942C819.672 229.789 823.686 237.524 826.78 242.41C835.481 253.969 853.173 256.451 865.026 255.081C861.697 252.047 861.544 239 861.083 234.339Z" fill="#F95409" stroke="black" stroke-width="3.19915" />
                <path d="M779.481 180.669C798.63 174.821 807.113 194.904 807.985 203.326L810.908 214.436C804.066 217.65 790.99 214.213 785.328 213.266C770.955 210.863 761.58 195.361 758.431 184.031C762.471 185.902 775.007 182.035 779.481 180.669Z" fill="#F95409" stroke="black" stroke-width="3.19915" />
                <path d="M802.674 165.06C793.852 175.904 801.443 196.471 809.3 204.572L829.521 202.976C845.94 176.437 832.292 155.473 818.731 147.971C817.186 150.44 814.845 160.072 802.674 165.06Z" fill="#F95409" stroke="black" stroke-width="3.19915" />
                <path d="M857.856 180.668C838.707 174.821 830.224 194.904 829.351 203.326L826.428 214.435C833.27 217.649 846.347 214.213 852.009 213.266C866.382 210.863 875.756 195.36 878.905 184.031C874.865 185.902 862.329 182.034 857.856 180.668Z" fill="#F95409" stroke="black" stroke-width="3.19915" />
                <ellipse cx="819.003" cy="210.423" rx="12.9024" ry="11.6736" fill="#FFDF80" />
                <path d="M819.309 221.483C804.563 223.941 800.305 197.733 820.231 198.443C825.414 199.01 834.055 204.28 830.305 215.495C829.139 218.981 822.12 223.633 816.851 220.374C814.295 218.602 811.061 213.189 815.054 208.273C816.851 206.061 822.688 205.509 822.996 210.424C822.996 210.424 822.996 214.417 819.309 214.11C818.666 214.057 817.374 213.496 817.727 211.96" stroke="black" stroke-width="1.8432" />
                <path d="M825.338 93.7137C825.338 97.1115 822.583 99.866 819.185 99.866C815.788 99.866 813.033 97.1115 813.033 93.7137C813.033 90.3159 815.788 87.5615 819.185 87.5615C822.583 87.5615 825.338 90.3159 825.338 93.7137ZM778.581 101.096C778.581 104.494 775.826 107.249 772.429 107.249C769.031 107.249 766.276 104.494 766.276 101.096C766.276 97.6986 769.031 94.9442 772.429 94.9442C775.826 94.9442 778.581 97.6986 778.581 101.096ZM739.207 130.627C739.207 134.025 736.452 136.779 733.054 136.779C729.657 136.779 726.902 134.025 726.902 130.627C726.902 127.229 729.657 124.475 733.054 124.475C736.452 124.475 739.207 127.229 739.207 130.627ZM714.598 177.384C714.598 180.782 711.843 183.536 708.446 183.536C705.048 183.536 702.293 180.782 702.293 177.384C702.293 173.986 705.048 171.232 708.446 171.232C711.843 171.232 714.598 173.986 714.598 177.384ZM704.754 236.445C704.754 239.843 702 242.598 698.602 242.598C695.204 242.598 692.45 239.843 692.45 236.445C692.45 233.048 695.204 230.293 698.602 230.293C702 230.293 704.754 233.048 704.754 236.445ZM865.942 107.249C862.544 107.249 859.79 104.494 859.79 101.096C859.79 97.6986 862.544 94.9442 865.942 94.9442C869.34 94.9442 872.094 97.6986 872.094 101.096C872.094 104.494 869.34 107.249 865.942 107.249ZM905.316 136.779C901.919 136.779 899.164 134.025 899.164 130.627C899.164 127.229 901.919 124.475 905.316 124.475C908.714 124.475 911.469 127.229 911.469 130.627C911.469 134.025 908.714 136.779 905.316 136.779ZM929.925 183.536C926.527 183.536 923.773 180.782 923.773 177.384C923.773 173.986 926.527 171.232 929.925 171.232C933.323 171.232 936.077 173.986 936.077 177.384C936.077 180.782 933.323 183.536 929.925 183.536ZM939.769 242.598C936.371 242.598 933.617 239.843 933.617 236.445C933.617 233.048 936.371 230.293 939.769 230.293C943.166 230.293 945.921 233.048 945.921 236.445C945.921 239.843 943.166 242.598 939.769 242.598Z" fill="#FFDF80" stroke="black" stroke-width="2.46089" />
              </svg>
            </div>
          </div>
        </div>
        {/* <div className="about-desk-separator" /> */}

        <div className="about-skills-container">
          <div className="about-skills-column">
            <img src={design_logo} className="about-skills-img" alt="design" />

            <p className="about-skills-column-title">
              Designer
              {/* <a href="#about-projects" className="about-link">Designer</a> */}
            </p>

            <p className="about-skills-list">
              I apply human-centered design thinking to create intuitable and purposeful products.
            </p>

            <img src={thought_big} className="about-skills-img-small" alt="design" />
            <p className="about-skills-column-subtitle">
              Skillsets
            </p>
            <ul className="about-skills-list">
              <li>Needfinding</li>
              <li>Concept models & mapping</li>
              <li>Rapid prototyping</li>
              <li>Digital interface design (UI, UX, Figma wireframes!)</li>
              <li>RITE usability testing</li>
              <li>Data visualization</li>
              <li>Game design</li>
              <li>3D CAD modeling, laser cutting, & more!</li>
              <li></li>
            </ul>

          </div>
          <div className="about-skills-column">
            <img src={dev_logo} className="about-skills-img" alt="develop" />

            <p className="about-skills-column-title">
              Developer
              {/* <a href="#about-projects" className="about-link">Developer</a> */}
            </p>
            <p className="about-skills-list">
              I bring ideas to life with modular and well-tested code.
            </p>

            <img src={thought_big} className="about-skills-img-small" alt="design" />
            <p className="about-skills-column-subtitle">
              Favorite Tools
            </p>
            <ul className="about-skills-list">
              <li>JavaScript (React, Node, Angular, D3)</li>
              <li>HTML & CSS</li>
              <li>Python (Django)</li>
              <li>AWS (DynamoDB, S3) & Google Firebase (Cloud Firestore)</li>
              <li>Game engines (Twine, Ren'Py, Unity)</li>
              <li>Git (GitHub, GitLab)</li>
              <li>Whatever I need, I can learn!</li>
            </ul>
          </div>

          <div className="about-skills-column">
            <img src={story_logo} className="about-skills-img" alt="story" />

            <p className="about-skills-column-title">
              Storyteller
              {/* <a href="#about-projects" className="about-link">Storyteller</a> */}
            </p>
            <p className="about-skills-list">
              I see the world through stories, and I love telling them too.
            </p>

            <img src={thought_big} className="about-skills-img-small" alt="design" />
            <p className="about-skills-column-subtitle">
              Interest Areas
            </p>
            <ul className="about-skills-list">
              <li>Creative writing (speculative fiction)</li>
              <li>Narrative-driven games (ludonarrative harmony!)</li>
              <li>Character design & worldbuilding</li>
              <li>User journey maps & design docs</li>
              <li>Data analysis & journalism</li>
              <li>Reviews & reflections</li>
              <li>And so much more!</li>
            </ul>

          </div>
        </div>

        {/* Temporary in-line styling until we fix the jump to nav" and case studies w/ the actual text*/}
        <div id="about-projects-container" className="about-projects-container">
          <Projects />
        </div>
      </div>
    );
  }
}

export default About;