import React from 'react';
import './portfolio.css';

// Image imports
import overgrowth_cover from "../../documents/Overgrowth_Cover.jpeg";
import with_what_eyes from "../../documents/with_what_eyes.png";
import corporate_vision from "../../documents/corporate_vision.jpeg";
import arch_stonehenge from "../../documents/ARCH_stonehenge.png";
import history_of_you_website from "../../documents/TheHistoryOfYou.png";
import civic_digital_fellowship from "../../documents/civicdigitalfellowship.png";
import wanderlust_route from "../../documents/wanderlust_route.png";
import art_wip from "../../documents/severalcircles_numbered.jpg";
import vocab_racer from "../../documents/vocabracer.png";
import washpost from "../../documents/washpost-logo.jpg";
import austenmania from "../../documents/austenmania.png";
import moma from "../../documents/MoMA.png";
import poetricks from "../../documents/poetricks.png";
import goodreads from "../../documents/goodreads.png";
import sff_writeup from "../../documents/sff_writeup.png";
import opposites_attract from "../../documents/opposites-attract.png";
import stanford_daily from "../../documents/stanforddaily-red-bg.jpg";
import plant_protection_program from "../../documents/plant_protection_program.jpeg";
import casper_corgi from "../../documents/CasperCorgi.JPG";

// Document imports
import corporate_vision_printable from "../../documents/corporate_vision_printable.pdf";
import arch_summary from "../../documents/ARCH_Overall.pdf";
import history_of_you_p1 from "../../documents/TheHistoryOfYou_Explainer.pdf";
import history_of_you_p2 from "../../documents/TheHistoryOfYou_Website.pdf";
import wanderlust_slides from "../../documents/wanderlust_slides.pdf";
import vocab_racer_poster from "../../documents/vocabracer_hifi_poster.pdf";
import vocab_racer_final_report from "../../documents/vocabracer_final_report.pdf";
import NYMOMA_data_exploration from "../../documents/NYMoMADataExploration_ShanaHadi.pdf";
import poetricks_slides from "../../documents/poetricks_slides_ShanaHadi.pdf";
import goodreads_data from "../../documents/GoodreadsDataExploration_ShanaHadi.pdf";
import SFF_shorts_writeup from "../../documents/SFFShortsWriteup_ShanaHadi.pdf";
import opposites_attract_writeup from "../../documents/OppositesAttract_Writeup_DevineHadiKeck.pdf";

class Projects extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      allProjects: document.getElementsByClassName('projects-single'),
    }

    this.filterSelection = this.filterSelection.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.addClass = this.addClass.bind(this);
  }

  filterSelection(filter) {
    const allProjects = this.state.allProjects;    //document.querySelectorAll(["classname=projects-single"])

    if (filter === "all") filter = "";
    for (let i = 0; i < allProjects.length; i++) {
      //remove "show" from the class name to reset
      this.removeClass(allProjects[i], "show");

      if (allProjects[i].className.indexOf(filter) > -1) {
        this.addClass(allProjects[i], "show");
      }
    }

    //Updates the style of the selected filter buttons
    let filterButtonsContainer = document.getElementById("projects-filter-button-container");
    let current = filterButtonsContainer.getElementsByClassName("active-button");
    //console.log("current", current);
    current[0].className = current[0].className.replace(" active-button", "");

    //console.log("filter: ", filter);
    if (!filter) filter = "all";
    let newActive = filterButtonsContainer.getElementsByClassName(filter)[0];

    //console.log("new active: ", newActive);
    newActive.className += " active-button";
  }

  addClass(projectDiv, className) {
    let projectDivClasses = projectDiv.className.split(" ");
    let classesToAdd = className.split(" ");
    for (let i = 0; i < classesToAdd.length; i++) {
      if (projectDivClasses.indexOf(classesToAdd[i] === -1)) {
        projectDiv.className += " " + classesToAdd[i];
      }
    }
  }

  removeClass(projectDiv, className) {
    let projectDivClasses = projectDiv.className.split(" ");
    let classesToRemove = className.split(" ");

    for (let i = 0; i < classesToRemove.length; i++) {
      while (projectDivClasses.indexOf(classesToRemove[i]) > -1) {
        projectDivClasses.splice(projectDivClasses.indexOf(classesToRemove[i], 1));
      }
    }

    projectDiv.className = projectDivClasses.join(" ");
  }

  componentDidMount() {
    this.filterSelection("featured");
    /* Scroll to top upon switching screens */
    window.scrollTo(0, 0)
  }

  render() {
    /* For large files, follow these instructions, track specific files https://git-lfs.github.com/ */
    /* Ideally this data would live in a json file, but because we require custom styling... here we are.*/ 
    return (
      <div className="projects-main">
        <h1 className="page-title">My Projects</h1>

        <div id="projects-filter-button-container" className="projects-filter-button-container">

          <button className="projects-filter-button featured active-button"
            onClick={() => this.filterSelection("featured")}
          >
            Featured
          </button>

          <button className="projects-filter-button games"
            onClick={() => this.filterSelection("games")}
          >
            Games
          </button>

          <button className="projects-filter-button code"
            onClick={() => this.filterSelection("code")}
          >
            Code
          </button>

          <button className="projects-filter-button ui-ux"
            onClick={() => this.filterSelection("ui-ux")}
          >
            UI / UX Design
          </button>

          <button className="projects-filter-button data"
            onClick={() => this.filterSelection("data")}
          >
            Data
          </button>

          <button className="projects-filter-button writing-art"
            onClick={() => this.filterSelection("writing-art")}
          >
            Writing / Art
          </button>

          <button className="projects-filter-button all"
            onClick={() => this.filterSelection("all")}
          >
            All
          </button>
        </div>

        <a href="https://mechanicsofmagic.com/2023/03/20/p4-refine-a-game-overgrowth%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2/" target="_blank" rel="noreferrer">
        <div className="projects-single featured games ui-ux design">
          <a href="https://mechanicsofmagic.com/2023/03/20/p4-refine-a-game-overgrowth%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2/" target="_blank" rel="noreferrer">
            <img src={overgrowth_cover} className="projects-img" alt="overgrowth, a board game with tactile pieces" />
          </a>
          <div className="projects-text">
            <a href="https://mechanicsofmagic.com/2023/03/20/p4-refine-a-game-overgrowth%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2/" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> Overgrowth: a serious game on sustainable urban development</p>
            </a>
            <p className="projects-text-explainer">
              In a three-week sprint, my team and I created a cooperative systems board game that teaches players about the challenges urban planners face when designing sustainable cities.
              We extensively prototyped, playtested, and iterated, with special attention to the information design in our rule sheet and onboarding to support our players' understanding of the game and key concepts!
            </p>

            <p className="projects-text-explainer">
              You and two other players are tasked by the state governor to create a "pioneer city" with net negative carbon emissions, reimagining a future where we work together to help resolve the climate crisis and live in harmony with nature.
              Use tactile pieces and build a model city before your eyes, see the short-term and long-term consequences of your urban planning, and get in the mindset (and heed the call to action!) of balancing human needs with the limits of the environment!
            </p>

            <p className="projects-text-explainer">
              <a href="https://mechanicsofmagic.com/2023/03/20/p4-refine-a-game-overgrowth%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2%e2%98%80%ef%b8%8f%f0%9f%8c%b2/" target="_blank" rel="noreferrer" className="projects-link">
                Read our case study here
              </a>
              , which documents our design process, Figma-fied instruction sheets, and "collector's edition"!
            </p>

            <p className="projects-text-explainer">
              <a href="https://drive.google.com/file/d/1ou7_GwMqJ205_WXWHDCJdPRN5OVw3hhp/view?usp=sharing" target="_blank" rel="noreferrer" className="projects-link">
                Print and play our game!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="https://mechanicsofmagic.com/2023/02/18/p2-the-future-we-deserve-with-what-eyes/" target="_blank" rel="noreferrer">
        <div className="projects-single games writing-art">
          <a href="https://mechanicsofmagic.com/2023/02/18/p2-the-future-we-deserve-with-what-eyes/" target="_blank" rel="noreferrer">
            <img src={with_what_eyes} className="projects-img" alt="with what eyes, an interactive fiction" />
          </a>
          <div className="projects-text">
            <a href="https://mechanicsofmagic.com/2023/02/18/p2-the-future-we-deserve-with-what-eyes/" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> with what eyes: an interactive speculative fiction on cyborgs, generative language models, and ethics</p>
            </a>
            <p className="projects-text-explainer">
              In three weeks, I created a narrative-based game, and prequel to my thesis e.l.i.z.a., that examines the impact of human augmentation and generative language on politics,
              especially with our preexisting human biases in perception toward queer and feminine figures.
            </p>

            <p className="projects-text-explainer">
              You play as Eliza, a self-effacing former Silicon Valley tech worker, who is hired to create a compelling persona for Ada, a lawyer and cyborg celebrity who hopes to run for office and distance herself from the shadow of her extremely wealthy husband.
              Eliza and Ada embody very different notions of the "cyborg" (a networked being), and while the game begins as an adversarial interview, it progreses into an "understanding" of how every body is a battleground.
            </p>

            <p className="projects-text-explainer">
              “With what eyes" do we claim to see?
            </p>
            <p className="projects-text-explainer">
              <a href="https://mechanicsofmagic.com/2023/02/18/p2-the-future-we-deserve-with-what-eyes/" target="_blank" rel="noreferrer" className="projects-link">
                Artist's statement
              </a> /                 <a href="https://shanaeh.github.io/with_what_eyes/with_what_eyes.html" target="_blank" rel="noreferrer" className="projects-link">
                Play the game here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="https://mechanicsofmagic.com/2023/01/26/p1-those-who-play-teach-corporate-vision/" target="_blank" rel="noreferrer">
        <div className="projects-single games">
          <a href="https://mechanicsofmagic.com/2023/01/26/p1-those-who-play-teach-corporate-vision/" target="_blank" rel="noreferrer">
            <img src={corporate_vision} className="projects-img" alt="corporate vision, a game to teach" />
          </a>
          <div className="projects-text">
            <a href="https://mechanicsofmagic.com/2023/01/26/p1-those-who-play-teach-corporate-vision/" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> corporate vision: a serious game on surveillance capitalism</p>
            </a>
            <p className="projects-text-explainer">
              In a two-week sprint, my team and I created a competitive social game  that teaches how corporations can use customers’ digital footprints
              to construct personas for more accurate targeted advertisements, contributing to the rise of what Professor Shoshana Zuboff calls “surveillance capitalism.”
              Players take turns as corporations that pitch ads based on "digital footprint" cards (like old receipts) to a customer who judges which advertisment would pique their interest.
            </p>

            <p className="projects-text-explainer">
              We aimed to raise awareness on corporate data-mining, model the predictive algorithmic system for "speculation on human futures," and inspire conversations on digital surveillance and its inextricable ties to capitalism.
            </p>
            <p className="projects-text-explainer">
              <a href="https://mechanicsofmagic.com/2023/01/26/p1-those-who-play-teach-corporate-vision/" target="_blank" rel="noreferrer" className="projects-link">
                Artist's statement
              </a> / <a href={corporate_vision_printable} target="_blank" rel="noreferrer" className="projects-link">
                Print and play our game!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={arch_summary} target="_blank" rel="noreferrer">
        <div className="projects-single featured code ui-ux games writing-art">
          <a href={arch_summary} target="_blank" rel="noreferrer">
            <img src={arch_stonehenge} className="projects-img" alt="ARCH, a VR project" />
          </a>
          <div className="projects-text">
            <a href={arch_summary} target="_blank" rel="noreferrer">
              <p className="projects-text-title"> ARCH: a Unity VR "thin slice" game for education, with Meta R&D</p>
            </a>
            <p className="projects-text-explainer">
              In under twenty weeks, partnering with Meta's Virtual Reality (VR) R&D lab (Global Historical Archive),
              my team and I explored how we could use the emerging medium of VR to facillitate long-lasting appreciation of and respect for world heritage sites.
              I immersed myself in the breadth of needfinding, ideation, prototyping, development, and product management.
            </p>

            <p className="projects-text-explainer">
              We created a Unity 3D “thin slice” proof-of-concept prototype at Stonehenge, with a dose of magical realism as "artifacts have stories."
              Some of our major user insights include how to "design for delight" and reward exploration within a scalable game loop, and solidifying a lofi to hifi prototyping framework for speedy VR development.
            </p>
            <p className="projects-text-explainer">
              We won first place at the 2022 Stanford Senior Software Project Demo Day! :)
            </p>
            <p className="projects-text-explainer">
              <a href={arch_summary} target="_blank" rel="noreferrer" className="projects-link">
                Read our case study here
              </a>
              , which documents our extensive human-centered design process and key insights!
            </p>
          </div>
        </div>
        </a>


        <a href="http://web.stanford.edu/class/cs247i/projects/F21/team4/" target="_blank" rel="noreferrer">
        <div className="projects-single featured code ui-ux games writing-art">
          <a href="http://web.stanford.edu/class/cs247i/projects/F21/team4/" target="_blank" rel="noreferrer">
            <img src={history_of_you_website} className="projects-img" alt="Interactive Fiction titled The History of You" />
          </a>
          <div className="projects-text">
            <a href="http://web.stanford.edu/class/cs247i/projects/F21/team4/" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> The History of You: an interactive explainer on intergenerational trauma</p>
            </a>
            <p className="projects-text-explainer">
              Within ten weeks of designing / developing interactive explainers about mental health (with a focus on intergenerational trauma) in autumn 2021,
              I immersed myself in the breadth of UI/UX research, prototyping, and web development. This project took place in 3 stages:
            </p>
            <p className="projects-text-explainer">
              1) First, I conducted several user interviews, designed the information
              flow and interactive elements, and wrote the overarching narrative and details for the interactive explainer (an interactive fiction in Twine, told in second-person across 3 generations).
              &nbsp;
              <a href={history_of_you_p1} target="_blank" rel="noreferrer" className="projects-link">
                Part 1 write-up!
              </a>
            </p>
            <p className="projects-text-explainer">
              2) Second, we researched 30+ supporting academic articles and collated these resources through a website we "hand-coded" from scratch without outside libraries using React.js, HTML/CSS.
              &nbsp;
              <a href={history_of_you_p2} target="_blank" rel="noreferrer" className="projects-link">
                Part 2 write-up!
              </a>
            </p>
            <p className="projects-text-explainer">
              3) Lastly, we designed, prototyped, playtested, and finalized a print-and-play healing-centric board game "From Roots to Shoots" that facillitated building interpersonal connections and enacted the process of healing from trauma
              through removing "trauma rocks" and growing flowers.
              &nbsp;
              <a href="https://drive.google.com/file/d/1rqQjzN0W-UgeaRhtr-s9tE-Ek1NOfBFd/view?usp=sharing" target="_blank" rel="noreferrer" className="projects-link">
                Part 3 write-up!
              </a>
            </p>
            <p className="projects-text-explainer">
              With our brand emphasis on grassroots and healing, we sought to center diverse second-generation immigrant perspectives in our research,
              choosing to use our own handmade, nature-oriented branding that we polished on Figma.
            </p>
            <p className="projects-text-explainer">
              <a href="http://web.stanford.edu/class/cs247i/projects/F21/team4/" target="_blank" rel="noreferrer" className="projects-link">
                Go to the final website here
              </a>
              , which has the interactive story and print-and-play board game!
            </p>
          </div>
        </div>
        </a>

        <a href="https://blog.codingitforward.com/meet-the-2021-fellows-u-s-census-bureau-bb6a745c925c" target="_blank" rel="noreferrer">
        <div className="projects-single code ui-ux">
          <a href="https://blog.codingitforward.com/meet-the-2021-fellows-u-s-census-bureau-bb6a745c925c" target="_blank" rel="noreferrer">
            <img src={civic_digital_fellowship} className="projects-img" alt="Civic Digital Fellowship at the U.S. Census Bureau" />
          </a>
          <div className="projects-text">
            <a href="https://blog.codingitforward.com/meet-the-2021-fellows-u-s-census-bureau-bb6a745c925c" target="_blank" rel="noreferrer">
              <p className="projects-text-title">Civic Digital Fellowship at the U.S. Census Bureau</p>
            </a>
            <p className="projects-text-explainer">
              Over ten weeks, plus a six-week part-time contract extension, I solo designed and developed a full-stack automated program to load in census survey data and process tabulations for the project’s deliverable with Django, Angular, HTML/CSS.
              A full expansion of this foundational code that helps process the allocation of 300+ million dollars to state and local governments will reduce analysts' manual work by 60-70%.
            </p>
            <p className="projects-text-explainer">
              <a href="https://github.com/codingitforward/cdfdemoday2021/blob/main/Shana_Hadi.pdf" target="_blank" rel="noreferrer" className="projects-link">
                Check out my demo day presentation!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={wanderlust_slides} target="_blank" rel="noreferrer">
        <div className="projects-single code ui-ux">
          <a href={wanderlust_slides} target="_blank" rel="noreferrer">
            <img src={wanderlust_route} className="projects-img" alt="vocab learning AI app" />
          </a>
          <div className="projects-text">
            <a href={wanderlust_slides} target="_blank" rel="noreferrer">
              <p className="projects-text-title">Wanderlust: explore a new route with whimsy</p>
            </a>
            <p className="projects-text-explainer">
              Over eight weeks of designing / developing with three teammates in spring 2021, we created Wanderlust, an iOS app that offers a unique outdoor exercise experience encouraging you to explore your local area. The app accepts a user's location and a desired distance, and will generate a semi-random route for the user to take for their run or bike ride! The app will also keep track of your past activities as well as the routes of other community members, while providing insightful statistics about the routes and your habits.
            </p>
            <p className="projects-text-explainer">
              We implemented our app with React Native and Firebase, with the Google Maps and Directions API to support our novel route generation "8-segmented legs algorithm" (map 8 possible vectors of 1/4 distance from the origin, divide each vector with 7 waypoints, assign a score based on length, and return the best vector and the best orthogonal vector leading from the origin; connect their two non-origin endpoints in the final route for a loop).
            </p>
            <p className="projects-text-explainer">
              <a href={wanderlust_slides} target="_blank" rel="noreferrer" className="projects-link">
                Check out our slides write-up!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="https://mechanicsofmagic.com/?p=1972" target="_blank" rel="noreferrer">
        <div className="projects-single ui-ux games">
          <a href="https://mechanicsofmagic.com/?p=1972" target="_blank" rel="noreferrer">
            <img src={art_wip} className="projects-img" alt="ArtWIP abstract art game" />
          </a>
          <div className="projects-text">
            <a href="https://mechanicsofmagic.com/?p=1972" target="_blank" rel="noreferrer">
              <p className="projects-text-title">ArtWIP: Hue are you? A journey through abstract paintings</p>
            </a>
            <p className="projects-text-explainer">
              Over four weeks of designing / prototyping / testing with four other teammates in spring 2021, we created a narrative-based walking simulator game that challenges the standard way to experience an abstract art painting, in which the player navigates the world of the canvas. Inspired by art movements such as Abstract Expressionism and De Stijl, we intend to emphasize the experience of being and moving within a space.
            </p>
            <p className="projects-text-explainer">
              With art pieces such as Kandinsky's "Several Circles," the soothing soundtrack and narrative fragments further cohere the game and encourage player self-reflection and reexamination of types of space as they journey through the painting as a tiny blob. We hope art enthusiasts and casual gamers alike would regard this game as a novel, digital way to explore abstract paintings and the meditative emotions the original artworks evoke.
            </p>
            <p className="projects-text-explainer">
              <a href="https://mechanicsofmagic.com/?p=1972" target="_blank" rel="noreferrer" className="projects-link">
                Check out our final case study write-up here
              </a>
              , which describes our ideating / prototyping / playtesting!
            </p>
            <p className="projects-text-explainer">
              <a href="https://tomjmwang.itch.io/cs247g" target="_blank" rel="noreferrer" className="projects-link">
                Play the prototype demo here
              </a>
              , implemented in Unity!
            </p>
          </div>
        </div>
        </a>

        <a href={vocab_racer_poster} target="_blank" rel="noreferrer">
        <div className="projects-single featured code ui-ux">
          <a href={vocab_racer_poster} target="_blank" rel="noreferrer">
            <img src={vocab_racer} className="projects-img" alt="vocab learning AI app" />
          </a>
          <div className="projects-text">
            <a href={vocab_racer_poster} target="_blank" rel="noreferrer">
              <p className="projects-text-title">VocabRacer: an immersive, image-based language-learning app</p>
            </a>
            <p className="projects-text-explainer">
              Over ten weeks of rapid-fire user research and lofi, medfi, and hifi prototyping with three teammates in winter 2021, we designed and prototyped an immersive language-learning app where you can upload photos, and the app will use AI-powered image recognition to tag parts of the photo with vocab words in your target language.
              You can create units, play image-based games with friends, and set goals for yourself to improve over time!
            </p>
            <p className="projects-text-explainer">
              We created paper and Figma wireframes for the lofi and medfi prototypes of the mobile app. We used React Native and Expo to implement the hifi prototype in conjunction with Google's Cloud Vision APIs for object recognition, object tagging, and Spanish-English translation.
            </p>
            <p className="projects-text-explainer">
              <a href={vocab_racer_final_report} target="_blank" rel="noreferrer" className="projects-link">
                Check out our final case study write-up
              </a>
              , which describes the course of our design journey with user research and prototypes!
            </p>
          </div>
        </div>
        </a>

        <a href="https://www.washingtonpost.com/elections/" target="_blank" rel="noreferrer">
        <div className="projects-single featured code ui-ux data">
          <a href="https://www.washingtonpost.com/elections/" target="_blank" rel="noreferrer">
            <img src={washpost} className="projects-img" alt="washington post internship" />
          </a>
          <div className="projects-text">
            <a href="https://www.washingtonpost.com/elections/" target="_blank" rel="noreferrer">
              <p className="projects-text-title">Election 2020 with The Washington Post's newsroom engineering team</p>
            </a>
            <p className="projects-text-explainer">
              Headline: an amazing learning opportunity in summer-fall-winter 2020-2021, during a truly unconventional election season!
            </p>
            <p className="projects-text-explainer">
              Frontend: Collaborated with Post designers and engineers and implemented / updated several visual components in React,
              such as a vote estimate header, voting results status table, state polling averages table, ranked races tables, state page links, and more.

            </p>
            <p className="projects-text-explainer">
              Backend: One super cool thing = diving into AWS to prototype and implement a race call Lambda in Python that would be activated
              upon vote provider updates to our DynamoDB table via streams; other steps included connecting it with and contributing to a text-generation repo,
              ultimately pushing updates to a Slack ElectionResultsBot webhook. I also wrote scripts to update DynamoDB entries and send processed data to the rendering engine...
              This came in quite handy during Election WEEK with race call updates for the central slack channel.
            </p>
            <p className="projects-text-explainer">
              While the main page spread has since changed, <a href="https://www.washingtonpost.com/elections/election-results/california-2020/?itid=sn_election-2020&itid=lk_inline_manual_9" target="_blank" rel="noreferrer" className="projects-link">
                check out one of the state pages!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="../../austenmania/austenmania.html" target="_blank" rel="noreferrer">
        <div className="projects-single featured data">
          <a href="../../austenmania/austenmania.html" target="_blank" rel="noreferrer">
            <img src={austenmania} className="projects-img" alt="austenmania data viz" />
          </a>
          <div className="projects-text">
            <a href="../../austenmania/austenmania.html" target="_blank" rel="noreferrer" className="projects-link">
              <p className="projects-text-title">austenmania: D3 data visualizations with NLP</p>
            </a>
            <p className="projects-text-explainer">
              Jane Austen (1775-1817) was an English novelist known for her wry wit, social commentary, and marriage plots, and she is one of my favorite authors.
              With my grounding in CS and English, I sought to implement a series of reader-led interactive data visualizations
              that would allow for a digitally "novel" way of reading her 700k+ words body of work.
            </p>
            <p className="projects-text-explainer">
              I processed data via writing my own Python scripts with the nltk library,
              designed the article to draw from computational literary analysis strategies and my own close readings of the novels,
              and implemented the visualizations using JS/HTML/CSS and D3 (which I am continuing to learn)!
              This current article is a lightweight version, with more updates forthcoming.
            </p>
            <p className="projects-text-explainer">
              Visualizations so far: 1) multi-novel paragraph chart, to view the novel shape as a glance and for word frequency searches across all 6 novels, along with a chapter-viewer for context.
              2) diverging bar chart for topic (and theme) modeling comparisons between novels and the corpus as a whole.
              3) line chart for main character mentions and their co-occurrences (i.e. the pattern of love interests and the foils) through "novel time."
            </p>

            <p className="projects-text-explainer">
              <a href="../../austenmania/austenmania.html" target="_blank" rel="noreferrer" className="projects-link">
                Read the article and interact with the data viz!
              </a>
            </p>

            <p className="projects-text-explainer">
              <a href="https://github.com/Shanaeh/austenmania" target="_blank" rel="noreferrer" className="projects-link">
                Check out my code on github
              </a>
              , which has D3 implementations + data-processing Python scripts!
            </p>
          </div>
        </div>
        </a>

        <a href={NYMOMA_data_exploration} target="_blank" rel="noreferrer">
        <div className="projects-single data">
          <a href={NYMOMA_data_exploration} target="_blank" rel="noreferrer">
            <img src={moma} className="projects-img" alt="MoMA data viz" />
          </a>
          <div className="projects-text">
            <a href={NYMOMA_data_exploration} target="_blank" rel="noreferrer">
              <p className="projects-text-title">NY Museum of Modern Art at a glance: exploratory data analysis</p>
            </a>
            <p className="projects-text-explainer">
              I have always wanted to visit the New York Museum of Modern Art, and I wondered what the metadata
              can reveal about the collection at a glance. I used the MuseumofModernArt github repo that I filtered
              down to 130,000 artworks, with a subset of 2366 paintings, during fall 2020.
            </p>
            <p className="projects-text-explainer">
              Guiding questions: 1) Which years are represented by artwork, and what is the delay for the MoMA acquiring pieces?
              2) Who are the painters with the most work at MoMA, what is their nationality and gender breakdown, and has female representation changed across time?
              3) Lastly, how has modern art’s dedication to experimentation manifested in formal elements like width and height?
            </p>
            <p className="projects-text-explainer">
              Major insights: Since the early 2000s, MoMA acquires more paintings per year than there are collection paintings created in that year.
              Approximately 49% of paintings are created by Americans; meanwhile, 87% of signed, non-group paintings are created by men as compared to 13% by women,
              which is slowly changing. Also, MoMA paintings tend to be wider than taller, even the grander ones.
            </p>
            <p className="projects-text-explainer">
              I used Trifacta Wrangler to clean extraneous symbols and organize attributes, and visualized my results in Tableau.
            </p>
            <p className="projects-text-explainer">
              <a href={NYMOMA_data_exploration} target="_blank" rel="noreferrer" className="projects-link">
                Check out my data exploration here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={poetricks_slides} target="_blank" rel="noreferrer">
        <div className="projects-single writing-art">
          <a href={poetricks_slides} target="_blank" rel="noreferrer">
            <img src={poetricks} className="projects-img" alt="poetricks poetic data viz" />
          </a>
          <div className="projects-text">
            <a href={poetricks_slides} target="_blank" rel="noreferrer">
              <p className="projects-text-title">poetricks: visualizing election results as poetry, "Rupi Kaur Race Calls"</p>
            </a>
            <p className="projects-text-explainer">
              Experimental project in React exploring how to use text-only poetic techniques, such as alignment,
              indentation, and repetition, to visualize election results, inspired from the work of
              poet Rupi Kaur and visual artist Christopher Wool, and my internship and mentors at The Washington Post.
            </p>
            <p className="projects-text-explainer">
              Rupi Kaur Race Calls: Instapoetry is meant to be accessible and easy to read, and similarly,
              I used AP's 2018 House results to experiment with indentation (left: DEM win, right: GOP win) and streamlined
              the presentation of information with indented, widely-spaced lines so viewers could quickly tell which party won the seat.
            </p>
            <p className="projects-text-explainer">
              Black Box Big Board: I drew from Christopher Wool's work with framed bold, large text and repeated elements
              to present 2016 presidential results as a mapping of 20 DEM states against 30 GOP states,
              with indented states (left: called for DEM, right: called for GOP) for a quick look at the nation at a glance.
            </p>
            <p className="projects-text-explainer">
              <a href={poetricks_slides} target="_blank" rel="noreferrer" className="projects-link">
                Check out my slides exploration here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={goodreads_data} target="_blank" rel="noreferrer">
        <div className="projects-single data">
          <a href={goodreads_data} target="_blank" rel="noreferrer">
            <img src={goodreads} className="projects-img" alt="goodreads all-star chart" />
          </a>
          <div className="projects-text">
            <a href={goodreads_data} target="_blank" rel="noreferrer">
              <p className="projects-text-title">Great reads (and star ratings) with Goodreads: exploratory data analysis with SQL and ML</p>
            </a>
            <p className="projects-text-explainer">
              Interested in how online, user-sourced book reviews can signal what is a "popular" book,
              I chose to explore what correlates with a higher # of ratings (as a placeholder for popularity, instead of skewed ratings values),
              using a forked dataset of Goodreads top 10k most-read books up until late 2017.
              Submitted for a final open-ended project for CS145 (Data Systems and Data Management) in fall 2019.
            </p>
            <p className="projects-text-explainer">
              Insights: An affirmation that author names, established book tags, and # of users that mark the work to-reads
              correlate with popular books. The proportion of n-star ratings to all ratings also resulted in a lovely visual spread,
              but were at most suggestive that popular books have a higher proportion of 4-star and 5-star ratings.
            </p>
            <p className="projects-text-explainer">
              I used Google Cloud Platform to run SQL queries and a machine learning logistic regression model for predicting the # of ratings,
              and I visualized results with Python and matplotlib.
            </p>
            <p className="projects-text-explainer">
              <a href={goodreads_data} target="_blank" rel="noreferrer" className="projects-link">
                Check out my data exploration here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={SFF_shorts_writeup} target="_blank" rel="noreferrer">
        <div className="projects-single data">
          <a href={SFF_shorts_writeup} target="_blank" rel="noreferrer">
            <img src={sff_writeup} className="projects-img" alt="sff writeup chart" />
          </a>
          <div className="projects-text">
            <a href={SFF_shorts_writeup} target="_blank" rel="noreferrer">
              <p className="projects-text-title">Hugo and Nebula short stories: exploratory data analysis with NLP</p>
            </a>
            <p className="projects-text-explainer">
              Continuing from my research internship at Literary Lab in summer 2019, I had the opportunity to
              lead my own mini computational literary criticism study. I searched for changes in themes within
              20 years (1999-2018) of Hugo- and Nebula-nominated short stories (corpus of 925k words), which could reflect the evolving composition of the voting population
              and their literary values (Hugo: voted on by fans, Nebula: by authors).
            </p>
            <p className="projects-text-explainer">
              Major insight: Nebula short stories were more likely to use female-specific pronouns,
              suggesting a greater range of female-identifying characters, and perhaps the greater inclusivity of these awards (unlike the Hugos, with their Rabid Puppies scandal).
            </p>
            <p className="projects-text-explainer">
              I scraped and cleaned the data with Python, ran several NLP topic models with Mallet, and visualized my results in Tableau.
            </p>
            <p className="projects-text-explainer">
              <a href={SFF_shorts_writeup} target="_blank" rel="noreferrer" className="projects-link">
                Check out my write-up here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href={opposites_attract_writeup} target="_blank" rel="noreferrer">
        <div className="projects-single ui-ux">
          <a href={opposites_attract_writeup} target="_blank" rel="noreferrer">
            <img src={opposites_attract} className="projects-img" alt="opposites attract" />
          </a>
          <div className="projects-text">
            <a href={opposites_attract_writeup} target="_blank" rel="noreferrer">
              <p className="projects-text-title">Opposites Attract: prototype for a social platform promoting conversations across borders</p>
            </a>
            <p className="projects-text-explainer">
              As part of a UI/UX design project with two other teammates, I designed, prototyped, and ran user testing to create a
              social platform that connects pairs of Stanford students across the political spectrum to have an anonymous and respectful
              discussion of their beliefs. Submitted as a final open-ended group project for CS278 (Social Computing) in spring 2019.
            </p>
            <p className="projects-text-explainer">
              This was made with "social bricolage," which means we pieced together a functional set-up from existing platforms,
              such as Google Forms, Google Docs, anonymous Facebook accounts, and Messenger chats in order to test
              the real crux of the matter: how can we intentionally design to foster bipartisan political conversations?
            </p>
            <p className="projects-text-explainer">
              <a href={opposites_attract_writeup} target="_blank" rel="noreferrer" className="projects-link">
                Check out the case study here!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="https://apps.apple.com/us/app/stanford-daily/id1341270063" target="_blank" rel="noreferrer">
        <div className="projects-single code ui-ux">
          <a href="https://apps.apple.com/us/app/stanford-daily/id1341270063" target="_blank" rel="noreferrer">
            <img src={stanford_daily} className="projects-img" alt="Stanford Daily logo" />
          </a>
          <div className="projects-text">
            <a href="https://apps.apple.com/us/app/stanford-daily/id1341270063" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> Stanford Daily Mobile App: frontend redesign for user engagement</p>
            </a>
            <p className="projects-text-explainer">
              I helped redesign The Stanford Daily mobile app during my CS independent study for fall-winter 2018-2019,
              working with faculty advisor Ann Grimes, the emerging Daily tech team, and Daily editors.
            </p>
            <p className="projects-text-explainer">
              I prototyped and implemented a section navigation bar depicting active published writers,
              and also contributed to user profiles, push notifications, and geo-tagged articles map display.
            </p>
            <p className="projects-text-explainer">
              <a href="https://apps.apple.com/us/app/stanford-daily/id1341270063" target="_blank" rel="noreferrer" className="projects-link">
                Check it out on the App Store!
              </a>
            </p>
          </div>
        </div>
        </a>

        <a href="https://github.com/Shanaeh/plant-protection-program" target="_blank" rel="noreferrer">
        <div className="projects-single code">
          <a href="https://github.com/Shanaeh/plant-protection-program" target="_blank" rel="noreferrer">
            <img src={plant_protection_program} className="projects-img" alt="raspberry pi A+" />
          </a>
          <div className="projects-text">
            <a href="https://github.com/Shanaeh/plant-protection-program" target="_blank" rel="noreferrer">
              <p className="projects-text-title">Raspberry Pi A+: bare-metal plant protection program in C</p>
            </a>
            <p className="projects-text-explainer">
              Using the Raspberry Pi A+ and bare-metal compatible sensors, I explored a question that still boggles me, how can we remember to water our plants?
              I wrangled low-level hardware manuals and implemented a library in C to use soil moisture / temperature / water sensors to output information onto a mini-lcd display.
              Submitted as a final open-ended project for CS107e (Systems from the Ground Up) in fall 2018.
            </p>
            <p className="projects-text-explainer">
              For nitty-gritty details, this involved writing in near-binary with bit manipulations, as the lcd display was turned on / off in sets of 8 Pi GPIO pins, with timer delays
              (such as 500 microseconds to signal data transfer).
            </p>
            <p className="projects-text-explainer">
              <a href="https://github.com/Shanaeh/plant-protection-program" target="_blank" rel="noreferrer" className="projects-link">
                Check out my code on github</a>, as well as my bare-metal operating system modules.
              I will forever be astonished at implementing the function printf()!
            </p>
          </div>
        </div>
        </a>

        <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer">
        <div className="projects-single code">
          <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer">
            <img src={casper_corgi} className="projects-img" alt="Casper the Corgi" />
          </a>
          <div className="projects-text">
            <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer">
              <p className="projects-text-title"> shanaeh.github.io!</p>
            </a>
            <p className="projects-text-explainer">I designed and built this site with React.js and HTML/CSS, along with accompanying tools such as Webpack, Babel, and Express.</p>

            <p className="projects-text-explainer">More projects are coming soon, and you can <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer" className="projects-link">
              check out a few of my projects on github</a>.
            </p>
            <p className="projects-text-explainer">Until then, Casper the Corgi wishes you a refreshing day. :) </p>
          </div>
        </div>
        </a>

      </div>

    );
  }
}

export default Projects;