import React from 'react';
import {
  Route, Routes, Link
} from 'react-router-dom';

import './styles/main.css';
import About from './components/About/About';
import Projects from './components/Portfolio/Projects';
import Writing from './components/Writing/Writing';

import logo from './documents/logo.svg';
import shana_resume from "./documents/ShanaHadi_ResumeJune2024.pdf";

import linkedin_logo from "./documents/linkedin.svg";
import github_logo from "./documents/github.svg";
import itchio_logo from "./documents/itchio.svg";
// import mail_icon from "./documents/email.svg";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
    //this.incrementCounter = this.incrementCounter.bind(this); //higher performance, != create new callback each time
  }

  render() {
    return (
      <div className="page-container">
        <div className="header">
          <div className="header-name">
            <Link to="/" className="header-name-link"><img src={logo} className="header-nav-img" alt="shana_logo" />
              Shana Hadi
            </Link>
          </div>

          <div className="header-nav">
            <Link to="/portfolio" className="header-nav-link">Portfolio</Link><span className="header-nav-separator">{" | "}</span>
            {/* <Link to="/projects" className="header-nav-link">Projects</Link><span className="header-nav-separator">{" | "}</span> */}
            {/* <Link to="/writing-art" className="header-nav-link">WIP</Link><span className="header-nav-separator">{" | "}</span> */}
            {/* <Link to="/exploratory" className="header-nav-link">Exploratory</Link><span className="header-nav-separator">{" | "}</span> */}
            {/* <a href={shana_resume} target="_blank" rel="noreferrer" className="header-nav-link">Resume</a> */}
            
            {/*Using special JS enviro variable so it's a neater link for my resume. Had to copy over the file directly into public.*/}
            <a href={process.env.PUBLIC_URL + "ShanaHadi_ResumeJune2024.pdf"} target="_blank" rel="noreferrer" className="header-nav-link">Resume</a>
          </div>
        </div>


{/*UMMM turns out these links don't work on GH Pages anymore??? UMMM that's cuz you're on create-react-app again*/}
        <div className="main-display">
          <Routes>
            <Route path={"*"} element={<About />} />
            <Route exact path={"/"} element={<About />} />
            <Route exact path={"/home"} element={<About />} />
            <Route exact path={"/portfolio"} element={<About />} />
            <Route exact path={"/projects"} element={<About />} />
            {/* <Route exact path={"/projects"} element={<Projects />} /> */}
            {/* <Route exact path={"/writing-art"} element={<Writing />} /> */}
          </Routes>
        </div>

        <div className="footer">
          <div className="footer-email-container">
            <a href="mailto:shana@shanahadi.com?subject=Hello!" className="footer-email">
              <div className="footer-email-column">
                <img src={logo} className="footer-logo" alt="mail" />
              </div>
              <div className="footer-email-column">
                <p className="footer-text">Interested in working together?</p>
                <a href="mailto:shana@shanahadi.com?subject=Hello!" className="projects-link footer-text">
                  Send me an email!
                </a> :)
              </div>
            </a>
          </div>
          <div className="footer-link-container">
            {/* <a href="mailto:shana@shanahadi.com?subject=Hello!">
                  <img src={mail_icon} className="footer-img" alt="mail" />
                </a> */}
            <a href="https://www.linkedin.com/in/shanahadi/" target="_blank" rel="noreferrer">
              <img src={linkedin_logo} className="footer-img" alt="linkedin" />
            </a>
            <a href="https://github.com/Shanaeh" target="_blank" rel="noreferrer">
              <img src={github_logo} className="footer-img" alt="github" />
            </a>
            <a href="https://shanaeh.itch.io/" target="_blank" rel="noreferrer">
              <img src={itchio_logo} className="footer-img" alt="itchio" />
            </a>
          </div>
          <p className="footer-text-handcraft">
            Made with <img src={logo} alt="shana_logo" style={{width: "20px", verticalAlign: "middle", marginBottom: "6px"}}/> by Shana Hadi (me!) with React, HTML, and CSS, hosted on GitHub Pages!
          </p>

        </div>

      </div>
    );
  }
}
export default App;