import React from 'react';

import eliza_tension from "../../documents/eliza_tension.png";
import art1 from "../../documents/art/monstress.png";
import art2 from "../../documents/art/duck.JPG";
import art3 from "../../documents/art/paper_wings.png";
import art4 from "../../documents/art/hot_air_balloons.JPG";
import art5 from "../../documents/art/heart_sketch.png";
import art6 from "../../documents/art/flower.JPG";
import art7 from "../../documents/art/valentine_pony.png";
import art8 from "../../documents/art/circuit.JPG";
import art9 from "../../documents/art/mushroom.png";
import art10 from "../../documents/art/paper_arches.JPG";
import art11 from "../../documents/art/redyellowblackwhite.JPG";
import art12 from "../../documents/art/delaunay_wip.JPG";

import two_teapots from "../../documents/TwoTeapots_Nov2021.pdf";
import sappho_extended from "../../documents/SapphoExtended_Nov2021.pdf";

class Writing extends React.Component {
  /* Scroll to top upon switching screens */
  componentDidMount() { 
    window.scrollTo(0, 0)
  }
  
  render() {    
      return (
        <div className="writing-main">
          {/*e.l.i.z.a. https://www.youtube.com/watch?v=J9c7pTi0RqY */}
          <p className="page-title">Creative Writing (Prose and Poetry)</p>
            <div className="writing-text">
              <div className="writing-single">
                <a href="https://www.youtube.com/watch?v=J9c7pTi0RqY" target = "_blank" rel="noreferrer" className="writing-link">
                  <span className="writing-title-big">e.l.i.z.a.</span>
                  <span className="writing-source"> (Stanford Honors in the Arts) </span>
                </a>
                <p className="writing-source"> Speculative fiction (SF) novella of 37,700 words told through a digital + traditional form, set in the future history country of Nusana. 
                The story focuses on two female leaders navigating challenges posed by NLP-generated legislation, individualized media-constructed personal realities, and the historical exclusion of women and minorities from the narratives of politics and technology. 
                {/* &nbsp; */}
                </p>
                <p className="writing-source"> "How long have you been always apologizing?"</p>
                <p className="writing-source">
                  <a href="https://www.youtube.com/watch?v=J9c7pTi0RqY" target = "_blank" rel="noreferrer">
                    <img src={eliza_tension} className="art-img" alt=""/>
                  </a>
                </p>
                <p className="writing-source"> 
                  <a href="https://www.youtube.com/watch?v=J9c7pTi0RqY" target = "_blank" rel="noreferrer" className="projects-link">
                  View my final video presentation on YouTube!
                  </a>                               
                </p>
                <p className="writing-source"> <b>WIP Note</b>: I am truly invested in this story, and I am currently writing thematically-related short stories to enrich the worldbuilding, and eventually once I have strengthened my skills, I will rewrite the story completely into an interactive digital format. </p>
              </div>
              <div className="writing-single">
                <a href="https://issuu.com/lelandquarterly/docs/lqws2019issuu" target = "_blank" rel="noreferrer" className="writing-link">
                  <span className="writing-title-big">"Craving"</span>
                  <span className="writing-source"> (Leland Quarterly: Winter and Spring 2019) </span>
                </a>
                <p className="writing-source"> SF short story on loneliness and a hunger for human connection. "What she gives breath to she also takes away." </p>
              </div>
              <div className="writing-single">
                <a href={two_teapots} target = "_blank" rel="noreferrer" className="writing-link">
                  <span className="writing-title-big">"Two Teapots"</span>
                  <span className="writing-source"> (Finalized Draft) </span>
                </a>
                <p className="writing-source"> SF short story that explores the fluidity of identity, the inheritance of family stories, and a teapot spirit. "Some families pass down property, clutches of pearls, a secret recipe – well, you get a teapot." </p>
              </div>
              <div className="writing-single">
                <a href={sappho_extended} target = "_blank" rel="noreferrer" className="writing-link">
                  <span className="writing-title-big">"Sappho, extended"</span>
                  <span className="writing-source"> (Finalized Draft) </span>
                </a>
                <p className="writing-source"> Experimental poem stitching together 21 surviving fragments of Ancient Greek poet Sappho's work (Carson translation) into a 3-part narrative on "this farce of love called longing." </p>
              </div>
            </div>

          <p className="page-title">Reviews and Nonfiction</p>
        
          <div className="writing-text">
            <div className="writing-single">
              <a href="https://stanfordrewired.com/post/so-much-cooking-and-digital-reading" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title-big">“So Much Cooking” — and Digital Reading</span>
                <span className="writing-source"> (Stanford Rewired) </span>
              </a>
              <p className="writing-source">Reflection and review of Naomi Kritzer's pandemic speculative fiction short story "So Much Cooking" and how digital reading will reshape our thinking</p>
            </div>
            <div className="writing-single">
              <a href="https://www.stanforddaily.com/author/shana-hadi/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title-big">Arts and Life Column</span>
                <span className="writing-source"> (The Stanford Daily) </span>
              </a>
              <p className="writing-source"> Current Senior Staff Writer, former Managing Editor (Summer-Fall 2019) and Reads Desk Editor (Fall 2018-Spring 2019).</p>
            </div>
            <li className="writing-single">
              <a href="https://www.stanforddaily.com/2019/12/04/notes-on-nanowrimo-the-aftermath/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title">Notes on NaNoWriMo: the aftermath</span>
                <span className="writing-source"> | <b>Reflection</b> on my month-long experience of zero-drafting a speculative fiction novel in thirty days, now the basis for a creative honors thesis!</span>
              </a>
            </li>
            <li className="writing-single">
              <a href="https://www.stanforddaily.com/2019/09/27/boba-guys-bursting-with-flavor/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title">Boba Guys, bursting with flavor</span>
                <span className="writing-source"> | <b>Review</b> for Foodie Friday (my Arts and Life initiative), discussing the chain's Palo Alto location and oat milk matcha latte</span>
              </a>
            </li>
            <li className="writing-single">
              <a href="https://www.stanforddaily.com/2019/05/10/reflections-from-the-bay-area-book-festival/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title">Reflections from the Bay Area Book Festival</span>
                <span className="writing-source"> | <b>Travelogue and essay</b> (and existential crisis), on writing, publishing, and a speculative fiction (SF) panel, with a brief conversation with SF author Alice Sola Kim</span>
              </a>
            </li>
            <li className="writing-single">
              <a href="https://www.stanforddaily.com/2019/03/06/unveiling-the-vela-an-interview-with-becky-chambers-s-l-huang-and-yoon-ha-lee-part-1/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title">Unveiling ‘The Vela’</span>
                <span className="writing-source"> | <b>Interview</b> with SF authors Becky Chambers, S. L. Huang, and Yoon Ha Lee on their Serial Box hit original, "The Vela"</span>
              </a>
            </li>
            <li className="writing-single">
              <a href="https://www.stanforddaily.com/2018/11/13/literary-bites/" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title">Literary Bites</span>
                <span className="writing-source"> | <b>Long-form feature piece</b> for The Stanford Daily Magazine on descriptions of food and their metaphorical implications in Woolf's essay "A Room of One's Own," Rumi's poem "Fasting" (Barks translation), and Dinesen's short story "Babette's Feast"</span>
              </a>
            </li>

            <div className="writing-single" style={{paddingTop: "20px"}}>
              <a href="https://www.creators.com/special-sections/santa-sleigh-2018/238557" target = "_blank" rel="noreferrer" className="writing-link">
                <span className="writing-title-big">Buying Books for Bibliophiles</span>
                <span className="writing-source"> (Creators Syndicate)</span>
                <p className="writing-source">Literary listicle bundled with a syndicated Special Section for December 2018 (Santa's Sleigh) </p>
              </a>
            </div>  
          </div>

          <p className="page-title">Art Dabbles</p>
          <p className="writing-source"> 
            <b>WIP Note</b>: Slowly but surely improving my sketching, digital art, and character design, with the occasional painting and pastel! :)
          </p>
          <div className="art-flex-gallery">
            <div className="art-flex-gallery-col">
            <img src={art1} className="art-img" alt=""/>
            <img src={art2} className="art-img" alt=""/>
            <img src={art3} className="art-img" alt=""/>
            <img src={art4} className="art-img" alt=""/>
            </div>
            <div className="art-flex-gallery-col">
            <img src={art5} className="art-img" alt=""/>
            <img src={art6} className="art-img" alt=""/>
            <img src={art7} className="art-img" alt=""/>
            <img src={art8} className="art-img" alt=""/>
            </div>
            <div className="art-flex-gallery-col">
            <img src={art9} className="art-img" alt=""/>
            <img src={art10} className="art-img" alt=""/>
            <img src={art11} className="art-img" alt=""/>
            <img src={art12} className="art-img" alt=""/>
            </div>
          </div>
        </div>
      );
    }
  }
  
export default Writing;